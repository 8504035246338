export const ticketListUrl = () => {
    return "/x-travel/user-ticket-list"
}

export const getBookingDetailsUrl = () => {
    return "/x-travel/booking-details"
}

export const bookSeatsUrl = () => {
    return "/x-travel/ssr-request"
}

export const singleTicketUrl = () => {
    return "/x-travel/single-ticket"
}

export const releasePnrUrl = () => {
    return "/x-travel/release-pnr"
}

export const cancelRequestUrl = () => {
    return "/x-travel/cancel-request"
}

export const cancelChargesUrl = () => {
    return "/x-travel/cancel-charges"
}
export const getTicketListBackofficeUrl = () => {
    return "/x-travel/ticket-list-backoffice"
}
export const getTicketRequestUrl = () => {
    return '/x-travel/get-requests'
}

export const reissueTicketUrl = () => {
    return "/x-travel/reissue-ticket"
}

export const updateRequestStatusUrl = () => {
    return "/x-travel/request-status"
}

export const getAndUpdateTicketDetailsUrl = () => {
    return "/x-travel/get-and-update-details"
}

export const calendarFareUrl = () => {
    return "/x-travel/calendar-fare"
}

export const sendMailUrl = () => {
    return "/x-travel/sendMail"
}

export const sendHoldingMailUrl = () => {
    return "/x-travel/sendHoldingMail"
}

