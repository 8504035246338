import React, {useEffect, useState} from 'react';
import {
    calculateTotalDuration,
    getLayoverCity,
    getStops, getTimeSlot,
    getUniqueAirlines,
    getUniqueFareTypes,
    calculateCheapestAndFastestFlights, groupFlightsFxn
} from "../commonFilterFxns";
import _ from "lodash";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

let filterArrInit = {
    stopsList: []
}
let refundFilterList = ['Non Refundable', 'Refundable']

const RangeSlider = (props) => {
    let {min = 0, max = 0, minValue = 0, maxValue = 100000} = props;

    // Ensure min and max are within the range
    min = Math.max(minValue, Math.min(min, maxValue));
    max = Math.max(minValue, Math.min(max, maxValue));

    // Calculate percentage positions
    let startPer = ((min - minValue) / (maxValue - minValue)) * 100;
    let endPer = ((max - minValue) / (maxValue - minValue)) * 100;

    return (
        <div className="slider-container">
            <div
                className="price-slider"
                style={{left: `${startPer}%`, right: `${100 - endPer}%`}}></div>
        </div>
    );
};


const FlightFilters = (props) => {
    const {flightList, setSortedFlights, title = '', priceRange: flightPriceRange, userRef, sortedFlights} = props
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {flightObj} = useSelector((state) => ({
        flightObj: state.flightsReducer.flightObj
    }))
    const [flightInfo, setFlightInfo] = useState([])
    const [filterLists, setFilterLists] = useState(filterArrInit)

    const [priceRange, setPriceRange] = useState({min: 0, max: 0});
    const [departureTime, setDepartureTime] = useState([]);
    const [airline, setAirline] = useState('');
    const [refundability, setRefundability] = useState();
    const [layoverCity, setLayoverCity] = useState('');
    const [stops, setStops] = useState('');
    const [selectedOption, setSelectedOption] = useState('');
    const [selectedSort, setSelectedSort] = useState('');
    const [showDetails, setShowDetails] = useState(true);
    const [selectedClass, setSelectedClass] = useState('');
    const [sortDirection, setSortDirection] = useState('asc');
    // const [sortedFlights, setSortedFlights] = useState([]);
    const [selectedAirline, setSelectedAirline] = useState('');
    const [selectedFareType, setSelectedFareType] = useState('');
    const [flightNumber, setFlightNumber] = useState('');
    const toggleDetails = () => setShowDetails(prevState => !prevState);
    const airlines = getUniqueAirlines(flightList);
    const fareTypes = getUniqueFareTypes(flightList);
    const stopsList = getStops(flightList);
    const layoverList = getLayoverCity(flightList);
    const {
        cheapestFlightDetails,
        fastestFlightDetails
    } = calculateCheapestAndFastestFlights(flightList);
    const handleFareTypeChange = ({target}) => {
        if (target.checked) {
            setSelectedFareType(target.value);
        } else {
            setSelectedFareType("");
        }
    };

    useEffect(() => {
        setPriceRange((prevState) => {
            return {
                ...prevState,
                ...flightPriceRange
            }
        })
    }, [flightPriceRange])

    const handleSortChange = (criteria) => {
        const newSortDirection = selectedOption === criteria ? (sortDirection === 'asc' ? 'desc' : 'asc') : 'asc';
        setSortDirection(newSortDirection);
        setSelectedOption(criteria);

        let sorted = [];


        if (criteria === 'flightNumber' && flightNumber) {
            sorted = flightList.filter(flight =>
                flight.Segments[0][0].Airline.FlightNumber.toString().includes(flightNumber)
            );
        } else {


        }

        setSortedFlights(sorted);
    };


    const handlePriceChange = (e, type) => {
        const value = parseInt(e.target.value);
        setPriceRange((prev) => (type === 'min' ? {...prev, min: value} : {...prev, max: value}));
    };

    const handleDepartureChange = (time) => {
        setDepartureTime((prev) =>
            prev.includes(time) ? prev.filter(item => item !== time) : [...prev, time]
        );
    };

    const handleAirlineChange = (e) => {
        if (e.target.checked) {
            setAirline(e.target.value);
            setSelectedAirline(e.target.value);
        } else {
            setAirline(undefined);
            setSelectedAirline(undefined);
        }
    };

    const handleRefundabilityChange = ({target}) => {
        if (target.checked) {
            setRefundability(target.value);
        } else {
            setRefundability(undefined);
        }
    };
    const handleLayoverChange = ({target}) => {
        if (target.checked) {
            setLayoverCity(target.value);
        } else {
            setLayoverCity(undefined);
        }
    };

    const handleStopsChange = ({target}) => {
        if (target.checked) {
            setStops(target.value);
        } else {
            setStops(undefined);
        }
    };


    const filterFlights = () => {
        const filtered = _.filter(flightList, (flight) => {
            let {FareClassification, Segments, Fare} = flight;
            let filterArr = []
            let firstSegment = Segments[0][0];
            const segmentList = _.clone(Segments[0]);
            segmentList.shift()
            let layoverListData = []
            _.each(segmentList, (item) => {
                if (item && item.Origin && item.Origin.Airport && item.Origin.Airport.AirportName) {
                    layoverListData.push(item.Origin.Airport.AirportName)
                }
            })
            const totalDuration = calculateTotalDuration(flight.Segments);
            const flightPrice = Fare.newFare;
            const flightDepartureTime = firstSegment.Origin.DepTime;
            const flightArrivalTime = firstSegment.Destination.ArrTime;

            filterArr.push(flightPrice >= priceRange.min && flightPrice <= priceRange.max);

            if (refundFilterList.includes(refundability)) {
                filterArr.push(refundability == 'Refundable' ? true : false);
            }
            if (selectedAirline) {
                filterArr.push(firstSegment.Airline.AirlineName.trim() == selectedAirline)
            }
            if (departureTime && departureTime.length) {
                filterArr.push(departureTime.includes(getTimeSlot(flightDepartureTime)))
            }
            if (selectedFareType) {
                filterArr.push(FareClassification?.Type == selectedFareType)
            }
            if (stops) {
                filterArr.push(Segments[0].length == stops)
            }
            if (selectedOption) {
                if (selectedOption == "cheapest") {
                    filterArr.push(flight['ResultIndex'] == cheapestFlightDetails['ResultIndex'])
                }
                if (selectedOption == "fastest") {
                    filterArr.push(flight['ResultIndex'] == fastestFlightDetails['ResultIndex'])
                }
            }
            if (flightNumber) {
                const regexField = new RegExp(flightNumber, 'i');
                filterArr.push((firstSegment.Airline.AirlineCode.match(regexField)) || (firstSegment.Airline.FlightNumber.match(regexField)))
            }

            if (layoverCity) {
                filterArr.push(layoverListData.includes(layoverCity))
            }

            return filterArr.every(Boolean);
        });
        if (filtered.length > 0) {
            setSortedFlights(filtered);
        } else if (sortedFlights.length > 0) {
            setSortedFlights(sortedFlights);
        }
    };


    useEffect(() => {
        filterFlights();
    }, [priceRange, departureTime, selectedAirline, refundability, layoverCity, stops, selectedOption, selectedFareType, flightNumber]);

    return (

        <div className="main-flight-filter">
            <div className="accordion" id="mainFlightFilter">
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                        <button className="accordion-button bg-white border-0" type="button"
                                data-bs-toggle="collapse" data-bs-target="#priceRange"
                                aria-expanded="true" aria-controls="priceRange">
                            {title} Price Range
                        </button>
                    </h2>
                    <div id="priceRange" className="accordion-collapse collapse show"
                         aria-labelledby="headingOne">
                        <div className="accordion-body">
                            <div className="price-input-container">
                                <RangeSlider min={priceRange.min} max={priceRange.max}
                                             minValue={flightPriceRange.min}
                                             maxValue={flightPriceRange.max}
                                             key={`${priceRange.min}-${priceRange.max}`}/>
                                <div className="range-input">
                                    <input
                                        type="range"
                                        className="min-range"
                                        min={flightPriceRange.min}
                                        max={flightPriceRange.max}
                                        value={priceRange.min || flightPriceRange.min}
                                        step="1"
                                        onChange={(e) => handlePriceChange(e, 'min')}
                                    />
                                    <input
                                        type="range"
                                        className="max-range"
                                        min={flightPriceRange.min}
                                        max={flightPriceRange.max}
                                        value={priceRange.max || flightPriceRange.max}
                                        step="1"
                                        onChange={(e) => handlePriceChange(e, 'max')}
                                    />
                                </div>
                                <div className="price-input">
                                    <div className="price-field">
                                        <span>Min. Price</span>
                                        <input
                                            type="number"
                                            className="min-input"
                                            value={priceRange.min || flightPriceRange.min}
                                            onChange={(e) => handlePriceChange(e, 'min')}
                                        />
                                    </div>
                                    <div className="price-field">
                                        <span>Max. Price</span>
                                        <input
                                            type="number"
                                            className="max-input"
                                            value={priceRange.max || flightPriceRange.max}
                                            onChange={(e) => handlePriceChange(e, 'max')}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Flight number */}
                {/*      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingFlightNo">
                            <button className="accordion-button bg-white border-0" type="button"
                                    data-bs-toggle="collapse" data-bs-target="#flightNoSearch"
                                    aria-expanded="true" aria-controls="flightNoSearch">
                                {title} Flight Number
                            </button>
                        </h2>
                        <div id="flightNoSearch" className="accordion-collapse collapse"
                             aria-labelledby="headingFlightNo" >
                            <div className="accordion-body">
                                <div className="flight-no-input-container position-relative">
                                    <input
                                        type="text"
                                        id="flightNumber"
                                        className="form-control ps-4"
                                        value={flightNumber}
                                        onChange={(e) => setFlightNumber(e.target.value)}
                                        placeholder="e.g. 123"/>
                                    <button
                                        className="search-icon-btn position-absolute top-50 end-0 translate-middle-y border-0 bg-transparent"
                                        onClick={() => handleSortChange('flightNumber')}
                                        type="button"
                                    >
                                        <i className="bx bx-search p-2"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>*/}

                {/* Flight Schedule */}
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                        <button className="accordion-button collapsed" type="button"
                                data-bs-toggle="collapse" data-bs-target="#flightSchedule"
                                aria-expanded="true" aria-controls="flightSchedule">
                            {title} Flight Schedule
                        </button>
                    </h2>
                    <div id="flightSchedule" className="accordion-collapse collapse show"
                         aria-labelledby="headingTwo">
                        <div className="accordion-body">
                            <div className="departure-radio">
                                <div className="tab-content" id="pills-tabContent">
                                    <div className="tab-pane fade show active"
                                         id="pills-departure" role="tabpanel"
                                         aria-labelledby="pills-departure-tab" tabIndex="0">
                                        <ul className="p-0 m-0">
                                            {['morning', 'noon', 'afternoon', 'night'].map((time, index) => (
                                                <li key={index}>
                                                    <input
                                                        type="checkbox"
                                                        name="departureTime"
                                                        checked={departureTime.includes(time)}
                                                        onChange={() => handleDepartureChange(time)}
                                                    />
                                                    <div className="box">
                                                        <img
                                                            src={`images2/${time}-icon.png`}
                                                            alt={`${time}-icon`}/>
                                                        <small>{time === 'morning' ? '00:00 - 11:59' : time === 'noon' ? '12:00 - 14:59' : time === 'afternoon' ? '15:00 - 17:59' : '18:00 - 23:59'}</small>
                                                        <h5>{time.charAt(0).toUpperCase() + time.slice(1)}</h5>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Airlines */}
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                        <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#airlines"
                            aria-expanded="true"
                            aria-controls="airlines"
                        >
                            {title} Airlines
                        </button>
                    </h2>
                    <div
                        id="airlines"
                        className="accordion-collapse collapse show"
                        aria-labelledby="headingThree"

                    >
                        <div className="accordion-body">
                            <div className="radio-filter">
                                <ul>
                                    {airlines.map((airline, index) => (
                                        <li key={index}>
                                            <input
                                                type="checkbox"
                                                name="airline"
                                                value={airline.name}
                                                checked={selectedAirline === airline.name}
                                                onChange={handleAirlineChange}/>
                                            <div className="box">
                                                <span></span>
                                                <small>{airline.name}</small>
                                                {/*<h5>Rs. {airline.newFare}</h5>*/}
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Fare Classification */}
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFareClassification">
                        <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#fareClassification"
                            aria-expanded="true"
                            aria-controls="fareClassification"
                        >
                            {title} Fare Classification
                        </button>
                    </h2>
                    <div
                        id="fareClassification"
                        className="accordion-collapse collapse show"
                        aria-labelledby="headingFareClassification"

                    >
                        <div className="accordion-body">
                            <div className="radio-filter">
                                <ul>

                                    {fareTypes.map((fareType, index) => (
                                        <li key={index}>
                                            <input
                                                type="checkbox"
                                                name="fareType"
                                                value={fareType}
                                                checked={selectedFareType === fareType}
                                                onChange={handleFareTypeChange}
                                            />
                                            <div className="box">
                                                <span></span>
                                                <small>{fareType}</small>
                                            </div>
                                        </li>
                                    ))}

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Refundability */}
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFour">
                        <button className="accordion-button collapsed" type="button"
                                data-bs-toggle="collapse" data-bs-target="#refundability"
                                aria-expanded="true" aria-controls="refundability">
                            {title} Refundability
                        </button>
                    </h2>
                    <div id="refundability" className="accordion-collapse collapse show"
                         aria-labelledby="headingFour">
                        <div className="accordion-body">
                            <div className="radio-filter">
                                <ul>

                                    {refundFilterList.map((option, index) => (
                                        <li key={index}>
                                            <input
                                                type="checkbox"
                                                name="refundability"
                                                value={option}
                                                checked={refundability === option}
                                                onChange={handleRefundabilityChange}
                                            />
                                            <div className="box">
                                                <span></span>
                                                <h5>{option}</h5>
                                            </div>
                                        </li>
                                    ))}
                                </ul>

                            </div>
                        </div>
                    </div>
                </div>

                {/* Layover */}
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFive">
                        <button className="accordion-button collapsed" type="button"
                                data-bs-toggle="collapse" data-bs-target="#layover"
                                aria-expanded="true" aria-controls="layover">
                            {title} Layover City
                        </button>
                    </h2>
                    <div id="layover" className="accordion-collapse collapse show"
                         aria-labelledby="headingFive">
                        <div className="accordion-body">
                            <div className="radio-filter">
                                <ul>
                                    {layoverList.map((city, index) => (
                                        <li key={index}>
                                            <input
                                                type="checkbox"
                                                name="layover"
                                                value={city.airportName}
                                                checked={layoverCity === city.airportName}
                                                onChange={handleLayoverChange}
                                            />
                                            <div className="box">
                                                <span></span>
                                                <small>{city.airportName}</small>
                                                <h5>{city.airportCode}</h5>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Stops */}
                <div className="accordion-item" id={'stops-accordion'}>
                    <h2 className="accordion-header" id="headingSix">
                        <button className="accordion-button collapsed" type="button"
                                data-bs-toggle="collapse" data-bs-target="#stops"
                                aria-expanded="true" aria-controls="stops">
                            {title} Stops
                        </button>
                    </h2>
                    <div id="stops" className="accordion-collapse collapse show"
                         aria-labelledby="headingSix">
                        <div className="accordion-body">
                            <div className="radio-filter">

                                <ul>
                                    {stopsList.map((stop, index) => (
                                        <li key={index}>
                                            <input
                                                type="checkbox"
                                                name="stops"
                                                value={stop}
                                                checked={stops == stop}
                                                onChange={handleStopsChange}
                                            />
                                            <div className="box">
                                                <span></span>
                                                <small>{stop - 1} stop(s)</small>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    );
};

export default FlightFilters;
