import moment from "moment";
import _ from "lodash"
import lodash from 'lodash'
import StateJson from "../../assets/jsonFiles/states.json"
import CityJson from "../../assets/jsonFiles/indiaCities.json"

export const flightTypeOption = [
    {display: "One-Way", id: "1"},
    {display: "Round-Trip", id: "2"},
    // {display: "Multi-Destination", id: "3"},
]
export const JourneyTypeObj = {
    "1": "One-Way",
    "2": "Round-Trip",
    "3": "Multi-Destination"
}
export const CabinTypeOptions = [
    {display: "Any", id: "1"},
    {display: "Economy", id: "2"},
    {display: "Premium Economy", id: "3"},
    {display: "Business", id: "4"},
    // {display: "Premium Business", id: "5"},
    {display: "First", id: "6"},
]
export const CabinObject = {
    "1": "Any",
    "2": "Economy",
    "3": "Premium Economy",
    "4": "Business",
    "5": "Premium Business",
    "6": "First"
}
export const adultOptions = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
export const childrenOptions = [0, 1, 2, 3, 4, 5, 6, 7, 8]
export const dateWithDay = (date) => {
    return moment(date).format('DD MMM, ddd')
}
export const displayTime = (date) => {
    return moment(date).format('hh:mm A')
}

export const displayDate = (date, format = 'MMM Do, HH:mm') => {
    return moment(date).format(format)
}
export const displayDateOnly = (date) => {
    // return moment(date).format('MMM Do, ddd')
    return moment(date).format('MMM Do YYYY, ddd')
}
export const minToHours = (minutes) => {
    if (minutes !== 0) {
        const hours = Math.floor(minutes / 60); // Calculate the whole hours
        const remainingMinutes = minutes % 60; // Calculate the remaining minutes
        return `${hours}hr ${remainingMinutes}min`; // Format the output
    } else {
        return null
    }
}
export const titleArr = [
    {name: "Mr", gender: 1},
    {name: "Ms", gender: 2},
    {name: "Mrs", gender: 2},
    {name: "Mstr", gender: 1},
    {name: "Miss", gender: 2}
]
export const genderArr = [
    {name: "Male", value: 1},
    {name: "Female", value: 2},
]
export const LoadState = countryId => {
    if (countryId) {
        let filterArr = lodash.filter(StateJson, item => {
            return item.country_id == countryId;
        });
        return filterArr;
    } else {
        return StateJson;
    }
};
export const LoadCity = stateId => {
    if (stateId) {
        let filterArrs = lodash.filter(CityJson, item => {
            return item.state_id == stateId
        })
        return filterArrs
    } else {
        return CityJson
    }
}
export const monthArr = [
    {name: 'January', key: 0},
    {name: 'February', key: 1},
    {name: 'March', key: 2},
    {name: 'April', key: 3},
    {name: 'May', key: 4},
    {name: 'June', key: 5},
    {name: 'July', key: 6},
    {name: 'August', key: 7},
    {name: 'September', key: 8},
    {name: 'October', key: 9},
    {name: 'November', key: 10},
    {name: 'December', key: 11}
]
export const getMonthName = (month) => {
    let findD = _.find(monthArr, (item) => {
        return item.key == month
    })
    return findD && findD.name ? findD.name : ""
}

export const daysArr = () => {
    let arr = []
    _.times(31, (item) => {
        arr.push(item + 1)
    })
    return arr;
}
export const yearArr = (type = "adult") => {
    /* let arr = [];
     let lessYear = type === "adult" ? 10 : type === "child" ? 1 : 0;
     let endYear = moment().subtract(lessYear, 'year').year();

     if (type === "child") {
         _.times(12, (item) => {
             arr.push(endYear - item);
         });
     } else if (type === "infant") {
         _.times(3, (item) => {
             arr.push(endYear - item);
         });
     } else {
         _.times(110, (item) => {
             arr.push(endYear - item);
         });
     }
     return arr;*/

    let arr = [];
    let lessYear = type === "adult" ? 12 : type === "child" ? 1 : 0;
    let endYear = new Date().getFullYear() - lessYear;
    if (type === "child") {
        for (let i = 0; i < 12; i++) {
            arr.push(endYear - i);
        }
    } else if (type === "infant") {
        for (let i = 0; i < 3; i++) {
            arr.push(endYear - i);
        }
    } else {
        for (let i = 0; i < 110; i++) {
            arr.push(endYear - i);
        }
    }
    return arr;

};


export const passengerTypeObject = {
    "1": "Adult",
    "2": "Child",
    "3": "Infant",
}
export const fixed2Digit = (value) => {
    if (value) {
        return parseFloat(parseFloat(value).toFixed(2))
    } else {
        return 0
    }
}
export const fareTypeList = [
    {name: "Regular Fares", value: "2"},
    {
        name: "Student Fares",
        value: "3",
        note: "Applicable for all students above the age of 12 years studying with a bonafide school/university. Valid photo ID and educational institute ID card need to be presented for verification at the airport."
    },
    /*   {
           name: "Marine Fares",
           value: "1",
           note: "Applicable for those joining or off signing a vessel, be it a merchant vessel, a cruise vessel with Valid CDC and relevant documents respective to country requirements for verification at the airport."
       },*/
    {
        name: "Senior Citizen",
        value: "5",
        note: "Applicable for only senior citizens above the age of 60 years can avail of this special fare. It is mandatory to present valid age proof at the airport."
    },
    /* {
         name: "Armed Forces",
         value: "4",
         note: "Applicable for all serving and retired Indian Armed Forces and Paramilitary Forces personnel. Valid photo ID and relevant military card need to be presented for verification at the airport."
     },*/
]

export const nationalityArr = [
    {
        "num_code": "4",
        "alpha_2_code": "AF",
        "alpha_3_code": "AFG",
        "name": "Afghanistan",
        "nationality": "Afghan"
    },
    {
        "num_code": "248",
        "alpha_2_code": "AX",
        "alpha_3_code": "ALA",
        "name": "\u00c5land Islands",
        "nationality": "\u00c5land Island"
    },
    {
        "num_code": "8",
        "alpha_2_code": "AL",
        "alpha_3_code": "ALB",
        "name": "Albania",
        "nationality": "Albanian"
    },
    {
        "num_code": "12",
        "alpha_2_code": "DZ",
        "alpha_3_code": "DZA",
        "name": "Algeria",
        "nationality": "Algerian"
    },
    {
        "num_code": "16",
        "alpha_2_code": "AS",
        "alpha_3_code": "ASM",
        "name": "American Samoa",
        "nationality": "American Samoan"
    },
    {
        "num_code": "20",
        "alpha_2_code": "AD",
        "alpha_3_code": "AND",
        "name": "Andorra",
        "nationality": "Andorran"
    },
    {
        "num_code": "24",
        "alpha_2_code": "AO",
        "alpha_3_code": "AGO",
        "name": "Angola",
        "nationality": "Angolan"
    },
    {
        "num_code": "660",
        "alpha_2_code": "AI",
        "alpha_3_code": "AIA",
        "name": "Anguilla",
        "nationality": "Anguillan"
    },
    {
        "num_code": "10",
        "alpha_2_code": "AQ",
        "alpha_3_code": "ATA",
        "name": "Antarctica",
        "nationality": "Antarctic"
    },
    {
        "num_code": "28",
        "alpha_2_code": "AG",
        "alpha_3_code": "ATG",
        "name": "Antigua and Barbuda",
        "nationality": "Antiguan or Barbudan"
    },
    {
        "num_code": "32",
        "alpha_2_code": "AR",
        "alpha_3_code": "ARG",
        "name": "Argentina",
        "nationality": "Argentine"
    },
    {
        "num_code": "51",
        "alpha_2_code": "AM",
        "alpha_3_code": "ARM",
        "name": "Armenia",
        "nationality": "Armenian"
    },
    {
        "num_code": "533",
        "alpha_2_code": "AW",
        "alpha_3_code": "ABW",
        "name": "Aruba",
        "nationality": "Aruban"
    },
    {
        "num_code": "36",
        "alpha_2_code": "AU",
        "alpha_3_code": "AUS",
        "name": "Australia",
        "nationality": "Australian"
    },
    {
        "num_code": "40",
        "alpha_2_code": "AT",
        "alpha_3_code": "AUT",
        "name": "Austria",
        "nationality": "Austrian"
    },
    {
        "num_code": "31",
        "alpha_2_code": "AZ",
        "alpha_3_code": "AZE",
        "name": "Azerbaijan",
        "nationality": "Azerbaijani, Azeri"
    },
    {
        "num_code": "44",
        "alpha_2_code": "BS",
        "alpha_3_code": "BHS",
        "name": "Bahamas",
        "nationality": "Bahamian"
    },
    {
        "num_code": "48",
        "alpha_2_code": "BH",
        "alpha_3_code": "BHR",
        "name": "Bahrain",
        "nationality": "Bahraini"
    },
    {
        "num_code": "50",
        "alpha_2_code": "BD",
        "alpha_3_code": "BGD",
        "name": "Bangladesh",
        "nationality": "Bangladeshi"
    },
    {
        "num_code": "52",
        "alpha_2_code": "BB",
        "alpha_3_code": "BRB",
        "name": "Barbados",
        "nationality": "Barbadian"
    },
    {
        "num_code": "112",
        "alpha_2_code": "BY",
        "alpha_3_code": "BLR",
        "name": "Belarus",
        "nationality": "Belarusian"
    },
    {
        "num_code": "56",
        "alpha_2_code": "BE",
        "alpha_3_code": "BEL",
        "name": "Belgium",
        "nationality": "Belgian"
    },
    {
        "num_code": "84",
        "alpha_2_code": "BZ",
        "alpha_3_code": "BLZ",
        "name": "Belize",
        "nationality": "Belizean"
    },
    {
        "num_code": "204",
        "alpha_2_code": "BJ",
        "alpha_3_code": "BEN",
        "name": "Benin",
        "nationality": "Beninese, Beninois"
    },
    {
        "num_code": "60",
        "alpha_2_code": "BM",
        "alpha_3_code": "BMU",
        "name": "Bermuda",
        "nationality": "Bermudian, Bermudan"
    },
    {
        "num_code": "64",
        "alpha_2_code": "BT",
        "alpha_3_code": "BTN",
        "name": "Bhutan",
        "nationality": "Bhutanese"
    },
    {
        "num_code": "68",
        "alpha_2_code": "BO",
        "alpha_3_code": "BOL",
        "name": "Bolivia (Plurinational State of)",
        "nationality": "Bolivian"
    },
    {
        "num_code": "535",
        "alpha_2_code": "BQ",
        "alpha_3_code": "BES",
        "name": "Bonaire, Sint Eustatius and Saba",
        "nationality": "Bonaire"
    },
    {
        "num_code": "70",
        "alpha_2_code": "BA",
        "alpha_3_code": "BIH",
        "name": "Bosnia and Herzegovina",
        "nationality": "Bosnian or Herzegovinian"
    },
    {
        "num_code": "72",
        "alpha_2_code": "BW",
        "alpha_3_code": "BWA",
        "name": "Botswana",
        "nationality": "Motswana, Botswanan"
    },
    {
        "num_code": "74",
        "alpha_2_code": "BV",
        "alpha_3_code": "BVT",
        "name": "Bouvet Island",
        "nationality": "Bouvet Island"
    },
    {
        "num_code": "76",
        "alpha_2_code": "BR",
        "alpha_3_code": "BRA",
        "name": "Brazil",
        "nationality": "Brazilian"
    },
    {
        "num_code": "86",
        "alpha_2_code": "IO",
        "alpha_3_code": "IOT",
        "name": "British Indian Ocean Territory",
        "nationality": "BIOT"
    },
    {
        "num_code": "96",
        "alpha_2_code": "BN",
        "alpha_3_code": "BRN",
        "name": "Brunei Darussalam",
        "nationality": "Bruneian"
    },
    {
        "num_code": "100",
        "alpha_2_code": "BG",
        "alpha_3_code": "BGR",
        "name": "Bulgaria",
        "nationality": "Bulgarian"
    },
    {
        "num_code": "854",
        "alpha_2_code": "BF",
        "alpha_3_code": "BFA",
        "name": "Burkina Faso",
        "nationality": "Burkinab\u00e9"
    },
    {
        "num_code": "108",
        "alpha_2_code": "BI",
        "alpha_3_code": "BDI",
        "name": "Burundi",
        "nationality": "Burundian"
    },
    {
        "num_code": "132",
        "alpha_2_code": "CV",
        "alpha_3_code": "CPV",
        "name": "Cabo Verde",
        "nationality": "Cabo Verdean"
    },
    {
        "num_code": "116",
        "alpha_2_code": "KH",
        "alpha_3_code": "KHM",
        "name": "Cambodia",
        "nationality": "Cambodian"
    },
    {
        "num_code": "120",
        "alpha_2_code": "CM",
        "alpha_3_code": "CMR",
        "name": "Cameroon",
        "nationality": "Cameroonian"
    },
    {
        "num_code": "124",
        "alpha_2_code": "CA",
        "alpha_3_code": "CAN",
        "name": "Canada",
        "nationality": "Canadian"
    },
    {
        "num_code": "136",
        "alpha_2_code": "KY",
        "alpha_3_code": "CYM",
        "name": "Cayman Islands",
        "nationality": "Caymanian"
    },
    {
        "num_code": "140",
        "alpha_2_code": "CF",
        "alpha_3_code": "CAF",
        "name": "Central African Republic",
        "nationality": "Central African"
    },
    {
        "num_code": "148",
        "alpha_2_code": "TD",
        "alpha_3_code": "TCD",
        "name": "Chad",
        "nationality": "Chadian"
    },
    {
        "num_code": "152",
        "alpha_2_code": "CL",
        "alpha_3_code": "CHL",
        "name": "Chile",
        "nationality": "Chilean"
    },
    {
        "num_code": "156",
        "alpha_2_code": "CN",
        "alpha_3_code": "CHN",
        "name": "China",
        "nationality": "Chinese"
    },
    {
        "num_code": "162",
        "alpha_2_code": "CX",
        "alpha_3_code": "CXR",
        "name": "Christmas Island",
        "nationality": "Christmas Island"
    },
    {
        "num_code": "166",
        "alpha_2_code": "CC",
        "alpha_3_code": "CCK",
        "name": "Cocos (Keeling) Islands",
        "nationality": "Cocos Island"
    },
    {
        "num_code": "170",
        "alpha_2_code": "CO",
        "alpha_3_code": "COL",
        "name": "Colombia",
        "nationality": "Colombian"
    },
    {
        "num_code": "174",
        "alpha_2_code": "KM",
        "alpha_3_code": "COM",
        "name": "Comoros",
        "nationality": "Comoran, Comorian"
    },
    {
        "num_code": "178",
        "alpha_2_code": "CG",
        "alpha_3_code": "COG",
        "name": "Congo (Republic of the)",
        "nationality": "Congolese"
    },
    {
        "num_code": "180",
        "alpha_2_code": "CD",
        "alpha_3_code": "COD",
        "name": "Congo (Democratic Republic of the)",
        "nationality": "Congolese"
    },
    {
        "num_code": "184",
        "alpha_2_code": "CK",
        "alpha_3_code": "COK",
        "name": "Cook Islands",
        "nationality": "Cook Island"
    },
    {
        "num_code": "188",
        "alpha_2_code": "CR",
        "alpha_3_code": "CRI",
        "name": "Costa Rica",
        "nationality": "Costa Rican"
    },
    {
        "num_code": "384",
        "alpha_2_code": "CI",
        "alpha_3_code": "CIV",
        "name": "C\u00f4te d'Ivoire",
        "nationality": "Ivorian"
    },
    {
        "num_code": "191",
        "alpha_2_code": "HR",
        "alpha_3_code": "HRV",
        "name": "Croatia",
        "nationality": "Croatian"
    },
    {
        "num_code": "192",
        "alpha_2_code": "CU",
        "alpha_3_code": "CUB",
        "name": "Cuba",
        "nationality": "Cuban"
    },
    {
        "num_code": "531",
        "alpha_2_code": "CW",
        "alpha_3_code": "CUW",
        "name": "Cura\u00e7ao",
        "nationality": "Cura\u00e7aoan"
    },
    {
        "num_code": "196",
        "alpha_2_code": "CY",
        "alpha_3_code": "CYP",
        "name": "Cyprus",
        "nationality": "Cypriot"
    },
    {
        "num_code": "203",
        "alpha_2_code": "CZ",
        "alpha_3_code": "CZE",
        "name": "Czech Republic",
        "nationality": "Czech"
    },
    {
        "num_code": "208",
        "alpha_2_code": "DK",
        "alpha_3_code": "DNK",
        "name": "Denmark",
        "nationality": "Danish"
    },
    {
        "num_code": "262",
        "alpha_2_code": "DJ",
        "alpha_3_code": "DJI",
        "name": "Djibouti",
        "nationality": "Djiboutian"
    },
    {
        "num_code": "212",
        "alpha_2_code": "DM",
        "alpha_3_code": "DMA",
        "name": "Dominica",
        "nationality": "Dominican"
    },
    {
        "num_code": "214",
        "alpha_2_code": "DO",
        "alpha_3_code": "DOM",
        "name": "Dominican Republic",
        "nationality": "Dominican"
    },
    {
        "num_code": "218",
        "alpha_2_code": "EC",
        "alpha_3_code": "ECU",
        "name": "Ecuador",
        "nationality": "Ecuadorian"
    },
    {
        "num_code": "818",
        "alpha_2_code": "EG",
        "alpha_3_code": "EGY",
        "name": "Egypt",
        "nationality": "Egyptian"
    },
    {
        "num_code": "222",
        "alpha_2_code": "SV",
        "alpha_3_code": "SLV",
        "name": "El Salvador",
        "nationality": "Salvadoran"
    },
    {
        "num_code": "226",
        "alpha_2_code": "GQ",
        "alpha_3_code": "GNQ",
        "name": "Equatorial Guinea",
        "nationality": "Equatorial Guinean, Equatoguinean"
    },
    {
        "num_code": "232",
        "alpha_2_code": "ER",
        "alpha_3_code": "ERI",
        "name": "Eritrea",
        "nationality": "Eritrean"
    },
    {
        "num_code": "233",
        "alpha_2_code": "EE",
        "alpha_3_code": "EST",
        "name": "Estonia",
        "nationality": "Estonian"
    },
    {
        "num_code": "231",
        "alpha_2_code": "ET",
        "alpha_3_code": "ETH",
        "name": "Ethiopia",
        "nationality": "Ethiopian"
    },
    {
        "num_code": "238",
        "alpha_2_code": "FK",
        "alpha_3_code": "FLK",
        "name": "Falkland Islands (Malvinas)",
        "nationality": "Falkland Island"
    },
    {
        "num_code": "234",
        "alpha_2_code": "FO",
        "alpha_3_code": "FRO",
        "name": "Faroe Islands",
        "nationality": "Faroese"
    },
    {
        "num_code": "242",
        "alpha_2_code": "FJ",
        "alpha_3_code": "FJI",
        "name": "Fiji",
        "nationality": "Fijian"
    },
    {
        "num_code": "246",
        "alpha_2_code": "FI",
        "alpha_3_code": "FIN",
        "name": "Finland",
        "nationality": "Finnish"
    },
    {
        "num_code": "250",
        "alpha_2_code": "FR",
        "alpha_3_code": "FRA",
        "name": "France",
        "nationality": "French"
    },
    {
        "num_code": "254",
        "alpha_2_code": "GF",
        "alpha_3_code": "GUF",
        "name": "French Guiana",
        "nationality": "French Guianese"
    },
    {
        "num_code": "258",
        "alpha_2_code": "PF",
        "alpha_3_code": "PYF",
        "name": "French Polynesia",
        "nationality": "French Polynesian"
    },
    {
        "num_code": "260",
        "alpha_2_code": "TF",
        "alpha_3_code": "ATF",
        "name": "French Southern Territories",
        "nationality": "French Southern Territories"
    },
    {
        "num_code": "266",
        "alpha_2_code": "GA",
        "alpha_3_code": "GAB",
        "name": "Gabon",
        "nationality": "Gabonese"
    },
    {
        "num_code": "270",
        "alpha_2_code": "GM",
        "alpha_3_code": "GMB",
        "name": "Gambia",
        "nationality": "Gambian"
    },
    {
        "num_code": "268",
        "alpha_2_code": "GE",
        "alpha_3_code": "GEO",
        "name": "Georgia",
        "nationality": "Georgian"
    },
    {
        "num_code": "276",
        "alpha_2_code": "DE",
        "alpha_3_code": "DEU",
        "name": "Germany",
        "nationality": "German"
    },
    {
        "num_code": "288",
        "alpha_2_code": "GH",
        "alpha_3_code": "GHA",
        "name": "Ghana",
        "nationality": "Ghanaian"
    },
    {
        "num_code": "292",
        "alpha_2_code": "GI",
        "alpha_3_code": "GIB",
        "name": "Gibraltar",
        "nationality": "Gibraltar"
    },
    {
        "num_code": "300",
        "alpha_2_code": "GR",
        "alpha_3_code": "GRC",
        "name": "Greece",
        "nationality": "Greek, Hellenic"
    },
    {
        "num_code": "304",
        "alpha_2_code": "GL",
        "alpha_3_code": "GRL",
        "name": "Greenland",
        "nationality": "Greenlandic"
    },
    {
        "num_code": "308",
        "alpha_2_code": "GD",
        "alpha_3_code": "GRD",
        "name": "Grenada",
        "nationality": "Grenadian"
    },
    {
        "num_code": "312",
        "alpha_2_code": "GP",
        "alpha_3_code": "GLP",
        "name": "Guadeloupe",
        "nationality": "Guadeloupe"
    },
    {
        "num_code": "316",
        "alpha_2_code": "GU",
        "alpha_3_code": "GUM",
        "name": "Guam",
        "nationality": "Guamanian, Guambat"
    },
    {
        "num_code": "320",
        "alpha_2_code": "GT",
        "alpha_3_code": "GTM",
        "name": "Guatemala",
        "nationality": "Guatemalan"
    },
    {
        "num_code": "831",
        "alpha_2_code": "GG",
        "alpha_3_code": "GGY",
        "name": "Guernsey",
        "nationality": "Channel Island"
    },
    {
        "num_code": "324",
        "alpha_2_code": "GN",
        "alpha_3_code": "GIN",
        "name": "Guinea",
        "nationality": "Guinean"
    },
    {
        "num_code": "624",
        "alpha_2_code": "GW",
        "alpha_3_code": "GNB",
        "name": "Guinea-Bissau",
        "nationality": "Bissau-Guinean"
    },
    {
        "num_code": "328",
        "alpha_2_code": "GY",
        "alpha_3_code": "GUY",
        "name": "Guyana",
        "nationality": "Guyanese"
    },
    {
        "num_code": "332",
        "alpha_2_code": "HT",
        "alpha_3_code": "HTI",
        "name": "Haiti",
        "nationality": "Haitian"
    },
    {
        "num_code": "334",
        "alpha_2_code": "HM",
        "alpha_3_code": "HMD",
        "name": "Heard Island and McDonald Islands",
        "nationality": "Heard Island or McDonald Islands"
    },
    {
        "num_code": "336",
        "alpha_2_code": "VA",
        "alpha_3_code": "VAT",
        "name": "Vatican City State",
        "nationality": "Vatican"
    },
    {
        "num_code": "340",
        "alpha_2_code": "HN",
        "alpha_3_code": "HND",
        "name": "Honduras",
        "nationality": "Honduran"
    },
    {
        "num_code": "344",
        "alpha_2_code": "HK",
        "alpha_3_code": "HKG",
        "name": "Hong Kong",
        "nationality": "Hong Kong, Hong Kongese"
    },
    {
        "num_code": "348",
        "alpha_2_code": "HU",
        "alpha_3_code": "HUN",
        "name": "Hungary",
        "nationality": "Hungarian, Magyar"
    },
    {
        "num_code": "352",
        "alpha_2_code": "IS",
        "alpha_3_code": "ISL",
        "name": "Iceland",
        "nationality": "Icelandic"
    },
    {
        "num_code": "356",
        "alpha_2_code": "IN",
        "alpha_3_code": "IND",
        "name": "India",
        "nationality": "Indian"
    },
    {
        "num_code": "360",
        "alpha_2_code": "ID",
        "alpha_3_code": "IDN",
        "name": "Indonesia",
        "nationality": "Indonesian"
    },
    {
        "num_code": "364",
        "alpha_2_code": "IR",
        "alpha_3_code": "IRN",
        "name": "Iran",
        "nationality": "Iranian, Persian"
    },
    {
        "num_code": "368",
        "alpha_2_code": "IQ",
        "alpha_3_code": "IRQ",
        "name": "Iraq",
        "nationality": "Iraqi"
    },
    {
        "num_code": "372",
        "alpha_2_code": "IE",
        "alpha_3_code": "IRL",
        "name": "Ireland",
        "nationality": "Irish"
    },
    {
        "num_code": "833",
        "alpha_2_code": "IM",
        "alpha_3_code": "IMN",
        "name": "Isle of Man",
        "nationality": "Manx"
    },
    {
        "num_code": "376",
        "alpha_2_code": "IL",
        "alpha_3_code": "ISR",
        "name": "Israel",
        "nationality": "Israeli"
    },
    {
        "num_code": "380",
        "alpha_2_code": "IT",
        "alpha_3_code": "ITA",
        "name": "Italy",
        "nationality": "Italian"
    },
    {
        "num_code": "388",
        "alpha_2_code": "JM",
        "alpha_3_code": "JAM",
        "name": "Jamaica",
        "nationality": "Jamaican"
    },
    {
        "num_code": "392",
        "alpha_2_code": "JP",
        "alpha_3_code": "JPN",
        "name": "Japan",
        "nationality": "Japanese"
    },
    {
        "num_code": "832",
        "alpha_2_code": "JE",
        "alpha_3_code": "JEY",
        "name": "Jersey",
        "nationality": "Channel Island"
    },
    {
        "num_code": "400",
        "alpha_2_code": "JO",
        "alpha_3_code": "JOR",
        "name": "Jordan",
        "nationality": "Jordanian"
    },
    {
        "num_code": "398",
        "alpha_2_code": "KZ",
        "alpha_3_code": "KAZ",
        "name": "Kazakhstan",
        "nationality": "Kazakhstani, Kazakh"
    },
    {
        "num_code": "404",
        "alpha_2_code": "KE",
        "alpha_3_code": "KEN",
        "name": "Kenya",
        "nationality": "Kenyan"
    },
    {
        "num_code": "296",
        "alpha_2_code": "KI",
        "alpha_3_code": "KIR",
        "name": "Kiribati",
        "nationality": "I-Kiribati"
    },
    {
        "num_code": "408",
        "alpha_2_code": "KP",
        "alpha_3_code": "PRK",
        "name": "Korea (Democratic People's Republic of)",
        "nationality": "North Korean"
    },
    {
        "num_code": "410",
        "alpha_2_code": "KR",
        "alpha_3_code": "KOR",
        "name": "Korea (Republic of)",
        "nationality": "South Korean"
    },
    {
        "num_code": "414",
        "alpha_2_code": "KW",
        "alpha_3_code": "KWT",
        "name": "Kuwait",
        "nationality": "Kuwaiti"
    },
    {
        "num_code": "417",
        "alpha_2_code": "KG",
        "alpha_3_code": "KGZ",
        "name": "Kyrgyzstan",
        "nationality": "Kyrgyzstani, Kyrgyz, Kirgiz, Kirghiz"
    },
    {
        "num_code": "418",
        "alpha_2_code": "LA",
        "alpha_3_code": "LAO",
        "name": "Lao People's Democratic Republic",
        "nationality": "Lao, Laotian"
    },
    {
        "num_code": "428",
        "alpha_2_code": "LV",
        "alpha_3_code": "LVA",
        "name": "Latvia",
        "nationality": "Latvian"
    },
    {
        "num_code": "422",
        "alpha_2_code": "LB",
        "alpha_3_code": "LBN",
        "name": "Lebanon",
        "nationality": "Lebanese"
    },
    {
        "num_code": "426",
        "alpha_2_code": "LS",
        "alpha_3_code": "LSO",
        "name": "Lesotho",
        "nationality": "Basotho"
    },
    {
        "num_code": "430",
        "alpha_2_code": "LR",
        "alpha_3_code": "LBR",
        "name": "Liberia",
        "nationality": "Liberian"
    },
    {
        "num_code": "434",
        "alpha_2_code": "LY",
        "alpha_3_code": "LBY",
        "name": "Libya",
        "nationality": "Libyan"
    },
    {
        "num_code": "438",
        "alpha_2_code": "LI",
        "alpha_3_code": "LIE",
        "name": "Liechtenstein",
        "nationality": "Liechtenstein"
    },
    {
        "num_code": "440",
        "alpha_2_code": "LT",
        "alpha_3_code": "LTU",
        "name": "Lithuania",
        "nationality": "Lithuanian"
    },
    {
        "num_code": "442",
        "alpha_2_code": "LU",
        "alpha_3_code": "LUX",
        "name": "Luxembourg",
        "nationality": "Luxembourg, Luxembourgish"
    },
    {
        "num_code": "446",
        "alpha_2_code": "MO",
        "alpha_3_code": "MAC",
        "name": "Macao",
        "nationality": "Macanese, Chinese"
    },
    {
        "num_code": "807",
        "alpha_2_code": "MK",
        "alpha_3_code": "MKD",
        "name": "Macedonia (the former Yugoslav Republic of)",
        "nationality": "Macedonian"
    },
    {
        "num_code": "450",
        "alpha_2_code": "MG",
        "alpha_3_code": "MDG",
        "name": "Madagascar",
        "nationality": "Malagasy"
    },
    {
        "num_code": "454",
        "alpha_2_code": "MW",
        "alpha_3_code": "MWI",
        "name": "Malawi",
        "nationality": "Malawian"
    },
    {
        "num_code": "458",
        "alpha_2_code": "MY",
        "alpha_3_code": "MYS",
        "name": "Malaysia",
        "nationality": "Malaysian"
    },
    {
        "num_code": "462",
        "alpha_2_code": "MV",
        "alpha_3_code": "MDV",
        "name": "Maldives",
        "nationality": "Maldivian"
    },
    {
        "num_code": "466",
        "alpha_2_code": "ML",
        "alpha_3_code": "MLI",
        "name": "Mali",
        "nationality": "Malian, Malinese"
    },
    {
        "num_code": "470",
        "alpha_2_code": "MT",
        "alpha_3_code": "MLT",
        "name": "Malta",
        "nationality": "Maltese"
    },
    {
        "num_code": "584",
        "alpha_2_code": "MH",
        "alpha_3_code": "MHL",
        "name": "Marshall Islands",
        "nationality": "Marshallese"
    },
    {
        "num_code": "474",
        "alpha_2_code": "MQ",
        "alpha_3_code": "MTQ",
        "name": "Martinique",
        "nationality": "Martiniquais, Martinican"
    },
    {
        "num_code": "478",
        "alpha_2_code": "MR",
        "alpha_3_code": "MRT",
        "name": "Mauritania",
        "nationality": "Mauritanian"
    },
    {
        "num_code": "480",
        "alpha_2_code": "MU",
        "alpha_3_code": "MUS",
        "name": "Mauritius",
        "nationality": "Mauritian"
    },
    {
        "num_code": "175",
        "alpha_2_code": "YT",
        "alpha_3_code": "MYT",
        "name": "Mayotte",
        "nationality": "Mahoran"
    },
    {
        "num_code": "484",
        "alpha_2_code": "MX",
        "alpha_3_code": "MEX",
        "name": "Mexico",
        "nationality": "Mexican"
    },
    {
        "num_code": "583",
        "alpha_2_code": "FM",
        "alpha_3_code": "FSM",
        "name": "Micronesia (Federated States of)",
        "nationality": "Micronesian"
    },
    {
        "num_code": "498",
        "alpha_2_code": "MD",
        "alpha_3_code": "MDA",
        "name": "Moldova (Republic of)",
        "nationality": "Moldovan"
    },
    {
        "num_code": "492",
        "alpha_2_code": "MC",
        "alpha_3_code": "MCO",
        "name": "Monaco",
        "nationality": "Mon\u00e9gasque, Monacan"
    },
    {
        "num_code": "496",
        "alpha_2_code": "MN",
        "alpha_3_code": "MNG",
        "name": "Mongolia",
        "nationality": "Mongolian"
    },
    {
        "num_code": "499",
        "alpha_2_code": "ME",
        "alpha_3_code": "MNE",
        "name": "Montenegro",
        "nationality": "Montenegrin"
    },
    {
        "num_code": "500",
        "alpha_2_code": "MS",
        "alpha_3_code": "MSR",
        "name": "Montserrat",
        "nationality": "Montserratian"
    },
    {
        "num_code": "504",
        "alpha_2_code": "MA",
        "alpha_3_code": "MAR",
        "name": "Morocco",
        "nationality": "Moroccan"
    },
    {
        "num_code": "508",
        "alpha_2_code": "MZ",
        "alpha_3_code": "MOZ",
        "name": "Mozambique",
        "nationality": "Mozambican"
    },
    {
        "num_code": "104",
        "alpha_2_code": "MM",
        "alpha_3_code": "MMR",
        "name": "Myanmar",
        "nationality": "Burmese"
    },
    {
        "num_code": "516",
        "alpha_2_code": "NA",
        "alpha_3_code": "NAM",
        "name": "Namibia",
        "nationality": "Namibian"
    },
    {
        "num_code": "520",
        "alpha_2_code": "NR",
        "alpha_3_code": "NRU",
        "name": "Nauru",
        "nationality": "Nauruan"
    },
    {
        "num_code": "524",
        "alpha_2_code": "NP",
        "alpha_3_code": "NPL",
        "name": "Nepal",
        "nationality": "Nepali, Nepalese"
    },
    {
        "num_code": "528",
        "alpha_2_code": "NL",
        "alpha_3_code": "NLD",
        "name": "Netherlands",
        "nationality": "Dutch, Netherlandic"
    },
    {
        "num_code": "540",
        "alpha_2_code": "NC",
        "alpha_3_code": "NCL",
        "name": "New Caledonia",
        "nationality": "New Caledonian"
    },
    {
        "num_code": "554",
        "alpha_2_code": "NZ",
        "alpha_3_code": "NZL",
        "name": "New Zealand",
        "nationality": "New Zealand, NZ"
    },
    {
        "num_code": "558",
        "alpha_2_code": "NI",
        "alpha_3_code": "NIC",
        "name": "Nicaragua",
        "nationality": "Nicaraguan"
    },
    {
        "num_code": "562",
        "alpha_2_code": "NE",
        "alpha_3_code": "NER",
        "name": "Niger",
        "nationality": "Nigerien"
    },
    {
        "num_code": "566",
        "alpha_2_code": "NG",
        "alpha_3_code": "NGA",
        "name": "Nigeria",
        "nationality": "Nigerian"
    },
    {
        "num_code": "570",
        "alpha_2_code": "NU",
        "alpha_3_code": "NIU",
        "name": "Niue",
        "nationality": "Niuean"
    },
    {
        "num_code": "574",
        "alpha_2_code": "NF",
        "alpha_3_code": "NFK",
        "name": "Norfolk Island",
        "nationality": "Norfolk Island"
    },
    {
        "num_code": "580",
        "alpha_2_code": "MP",
        "alpha_3_code": "MNP",
        "name": "Northern Mariana Islands",
        "nationality": "Northern Marianan"
    },
    {
        "num_code": "578",
        "alpha_2_code": "NO",
        "alpha_3_code": "NOR",
        "name": "Norway",
        "nationality": "Norwegian"
    },
    {
        "num_code": "512",
        "alpha_2_code": "OM",
        "alpha_3_code": "OMN",
        "name": "Oman",
        "nationality": "Omani"
    },
    {
        "num_code": "586",
        "alpha_2_code": "PK",
        "alpha_3_code": "PAK",
        "name": "Pakistan",
        "nationality": "Pakistani"
    },
    {
        "num_code": "585",
        "alpha_2_code": "PW",
        "alpha_3_code": "PLW",
        "name": "Palau",
        "nationality": "Palauan"
    },
    {
        "num_code": "275",
        "alpha_2_code": "PS",
        "alpha_3_code": "PSE",
        "name": "Palestine, State of",
        "nationality": "Palestinian"
    },
    {
        "num_code": "591",
        "alpha_2_code": "PA",
        "alpha_3_code": "PAN",
        "name": "Panama",
        "nationality": "Panamanian"
    },
    {
        "num_code": "598",
        "alpha_2_code": "PG",
        "alpha_3_code": "PNG",
        "name": "Papua New Guinea",
        "nationality": "Papua New Guinean, Papuan"
    },
    {
        "num_code": "600",
        "alpha_2_code": "PY",
        "alpha_3_code": "PRY",
        "name": "Paraguay",
        "nationality": "Paraguayan"
    },
    {
        "num_code": "604",
        "alpha_2_code": "PE",
        "alpha_3_code": "PER",
        "name": "Peru",
        "nationality": "Peruvian"
    },
    {
        "num_code": "608",
        "alpha_2_code": "PH",
        "alpha_3_code": "PHL",
        "name": "Philippines",
        "nationality": "Philippine, Filipino"
    },
    {
        "num_code": "612",
        "alpha_2_code": "PN",
        "alpha_3_code": "PCN",
        "name": "Pitcairn",
        "nationality": "Pitcairn Island"
    },
    {
        "num_code": "616",
        "alpha_2_code": "PL",
        "alpha_3_code": "POL",
        "name": "Poland",
        "nationality": "Polish"
    },
    {
        "num_code": "620",
        "alpha_2_code": "PT",
        "alpha_3_code": "PRT",
        "name": "Portugal",
        "nationality": "Portuguese"
    },
    {
        "num_code": "630",
        "alpha_2_code": "PR",
        "alpha_3_code": "PRI",
        "name": "Puerto Rico",
        "nationality": "Puerto Rican"
    },
    {
        "num_code": "634",
        "alpha_2_code": "QA",
        "alpha_3_code": "QAT",
        "name": "Qatar",
        "nationality": "Qatari"
    },
    {
        "num_code": "638",
        "alpha_2_code": "RE",
        "alpha_3_code": "REU",
        "name": "R\u00e9union",
        "nationality": "R\u00e9unionese, R\u00e9unionnais"
    },
    {
        "num_code": "642",
        "alpha_2_code": "RO",
        "alpha_3_code": "ROU",
        "name": "Romania",
        "nationality": "Romanian"
    },
    {
        "num_code": "643",
        "alpha_2_code": "RU",
        "alpha_3_code": "RUS",
        "name": "Russian Federation",
        "nationality": "Russian"
    },
    {
        "num_code": "646",
        "alpha_2_code": "RW",
        "alpha_3_code": "RWA",
        "name": "Rwanda",
        "nationality": "Rwandan"
    },
    {
        "num_code": "652",
        "alpha_2_code": "BL",
        "alpha_3_code": "BLM",
        "name": "Saint Barth\u00e9lemy",
        "nationality": "Barth\u00e9lemois"
    },
    {
        "num_code": "654",
        "alpha_2_code": "SH",
        "alpha_3_code": "SHN",
        "name": "Saint Helena, Ascension and Tristan da Cunha",
        "nationality": "Saint Helenian"
    },
    {
        "num_code": "659",
        "alpha_2_code": "KN",
        "alpha_3_code": "KNA",
        "name": "Saint Kitts and Nevis",
        "nationality": "Kittitian or Nevisian"
    },
    {
        "num_code": "662",
        "alpha_2_code": "LC",
        "alpha_3_code": "LCA",
        "name": "Saint Lucia",
        "nationality": "Saint Lucian"
    },
    {
        "num_code": "663",
        "alpha_2_code": "MF",
        "alpha_3_code": "MAF",
        "name": "Saint Martin (French part)",
        "nationality": "Saint-Martinoise"
    },
    {
        "num_code": "666",
        "alpha_2_code": "PM",
        "alpha_3_code": "SPM",
        "name": "Saint Pierre and Miquelon",
        "nationality": "Saint-Pierrais or Miquelonnais"
    },
    {
        "num_code": "670",
        "alpha_2_code": "VC",
        "alpha_3_code": "VCT",
        "name": "Saint Vincent and the Grenadines",
        "nationality": "Saint Vincentian, Vincentian"
    },
    {
        "num_code": "882",
        "alpha_2_code": "WS",
        "alpha_3_code": "WSM",
        "name": "Samoa",
        "nationality": "Samoan"
    },
    {
        "num_code": "674",
        "alpha_2_code": "SM",
        "alpha_3_code": "SMR",
        "name": "San Marino",
        "nationality": "Sammarinese"
    },
    {
        "num_code": "678",
        "alpha_2_code": "ST",
        "alpha_3_code": "STP",
        "name": "Sao Tome and Principe",
        "nationality": "S\u00e3o Tom\u00e9an"
    },
    {
        "num_code": "682",
        "alpha_2_code": "SA",
        "alpha_3_code": "SAU",
        "name": "Saudi Arabia",
        "nationality": "Saudi, Saudi Arabian"
    },
    {
        "num_code": "686",
        "alpha_2_code": "SN",
        "alpha_3_code": "SEN",
        "name": "Senegal",
        "nationality": "Senegalese"
    },
    {
        "num_code": "688",
        "alpha_2_code": "RS",
        "alpha_3_code": "SRB",
        "name": "Serbia",
        "nationality": "Serbian"
    },
    {
        "num_code": "690",
        "alpha_2_code": "SC",
        "alpha_3_code": "SYC",
        "name": "Seychelles",
        "nationality": "Seychellois"
    },
    {
        "num_code": "694",
        "alpha_2_code": "SL",
        "alpha_3_code": "SLE",
        "name": "Sierra Leone",
        "nationality": "Sierra Leonean"
    },
    {
        "num_code": "702",
        "alpha_2_code": "SG",
        "alpha_3_code": "SGP",
        "name": "Singapore",
        "nationality": "Singaporean"
    },
    {
        "num_code": "534",
        "alpha_2_code": "SX",
        "alpha_3_code": "SXM",
        "name": "Sint Maarten (Dutch part)",
        "nationality": "Sint Maarten"
    },
    {
        "num_code": "703",
        "alpha_2_code": "SK",
        "alpha_3_code": "SVK",
        "name": "Slovakia",
        "nationality": "Slovak"
    },
    {
        "num_code": "705",
        "alpha_2_code": "SI",
        "alpha_3_code": "SVN",
        "name": "Slovenia",
        "nationality": "Slovenian, Slovene"
    },
    {
        "num_code": "90",
        "alpha_2_code": "SB",
        "alpha_3_code": "SLB",
        "name": "Solomon Islands",
        "nationality": "Solomon Island"
    },
    {
        "num_code": "706",
        "alpha_2_code": "SO",
        "alpha_3_code": "SOM",
        "name": "Somalia",
        "nationality": "Somali, Somalian"
    },
    {
        "num_code": "710",
        "alpha_2_code": "ZA",
        "alpha_3_code": "ZAF",
        "name": "South Africa",
        "nationality": "South African"
    },
    {
        "num_code": "239",
        "alpha_2_code": "GS",
        "alpha_3_code": "SGS",
        "name": "South Georgia and the South Sandwich Islands",
        "nationality": "South Georgia or South Sandwich Islands"
    },
    {
        "num_code": "728",
        "alpha_2_code": "SS",
        "alpha_3_code": "SSD",
        "name": "South Sudan",
        "nationality": "South Sudanese"
    },
    {
        "num_code": "724",
        "alpha_2_code": "ES",
        "alpha_3_code": "ESP",
        "name": "Spain",
        "nationality": "Spanish"
    },
    {
        "num_code": "144",
        "alpha_2_code": "LK",
        "alpha_3_code": "LKA",
        "name": "Sri Lanka",
        "nationality": "Sri Lankan"
    },
    {
        "num_code": "729",
        "alpha_2_code": "SD",
        "alpha_3_code": "SDN",
        "name": "Sudan",
        "nationality": "Sudanese"
    },
    {
        "num_code": "740",
        "alpha_2_code": "SR",
        "alpha_3_code": "SUR",
        "name": "Suriname",
        "nationality": "Surinamese"
    },
    {
        "num_code": "744",
        "alpha_2_code": "SJ",
        "alpha_3_code": "SJM",
        "name": "Svalbard and Jan Mayen",
        "nationality": "Svalbard"
    },
    {
        "num_code": "748",
        "alpha_2_code": "SZ",
        "alpha_3_code": "SWZ",
        "name": "Swaziland",
        "nationality": "Swazi"
    },
    {
        "num_code": "752",
        "alpha_2_code": "SE",
        "alpha_3_code": "SWE",
        "name": "Sweden",
        "nationality": "Swedish"
    },
    {
        "num_code": "756",
        "alpha_2_code": "CH",
        "alpha_3_code": "CHE",
        "name": "Switzerland",
        "nationality": "Swiss"
    },
    {
        "num_code": "760",
        "alpha_2_code": "SY",
        "alpha_3_code": "SYR",
        "name": "Syrian Arab Republic",
        "nationality": "Syrian"
    },
    {
        "num_code": "158",
        "alpha_2_code": "TW",
        "alpha_3_code": "TWN",
        "name": "Taiwan, Province of China",
        "nationality": "Chinese, Taiwanese"
    },
    {
        "num_code": "762",
        "alpha_2_code": "TJ",
        "alpha_3_code": "TJK",
        "name": "Tajikistan",
        "nationality": "Tajikistani"
    },
    {
        "num_code": "834",
        "alpha_2_code": "TZ",
        "alpha_3_code": "TZA",
        "name": "Tanzania, United Republic of",
        "nationality": "Tanzanian"
    },
    {
        "num_code": "764",
        "alpha_2_code": "TH",
        "alpha_3_code": "THA",
        "name": "Thailand",
        "nationality": "Thai"
    },
    {
        "num_code": "626",
        "alpha_2_code": "TL",
        "alpha_3_code": "TLS",
        "name": "Timor-Leste",
        "nationality": "Timorese"
    },
    {
        "num_code": "768",
        "alpha_2_code": "TG",
        "alpha_3_code": "TGO",
        "name": "Togo",
        "nationality": "Togolese"
    },
    {
        "num_code": "772",
        "alpha_2_code": "TK",
        "alpha_3_code": "TKL",
        "name": "Tokelau",
        "nationality": "Tokelauan"
    },
    {
        "num_code": "776",
        "alpha_2_code": "TO",
        "alpha_3_code": "TON",
        "name": "Tonga",
        "nationality": "Tongan"
    },
    {
        "num_code": "780",
        "alpha_2_code": "TT",
        "alpha_3_code": "TTO",
        "name": "Trinidad and Tobago",
        "nationality": "Trinidadian or Tobagonian"
    },
    {
        "num_code": "788",
        "alpha_2_code": "TN",
        "alpha_3_code": "TUN",
        "name": "Tunisia",
        "nationality": "Tunisian"
    },
    {
        "num_code": "792",
        "alpha_2_code": "TR",
        "alpha_3_code": "TUR",
        "name": "Turkey",
        "nationality": "Turkish"
    },
    {
        "num_code": "795",
        "alpha_2_code": "TM",
        "alpha_3_code": "TKM",
        "name": "Turkmenistan",
        "nationality": "Turkmen"
    },
    {
        "num_code": "796",
        "alpha_2_code": "TC",
        "alpha_3_code": "TCA",
        "name": "Turks and Caicos Islands",
        "nationality": "Turks and Caicos Island"
    },
    {
        "num_code": "798",
        "alpha_2_code": "TV",
        "alpha_3_code": "TUV",
        "name": "Tuvalu",
        "nationality": "Tuvaluan"
    },
    {
        "num_code": "800",
        "alpha_2_code": "UG",
        "alpha_3_code": "UGA",
        "name": "Uganda",
        "nationality": "Ugandan"
    },
    {
        "num_code": "804",
        "alpha_2_code": "UA",
        "alpha_3_code": "UKR",
        "name": "Ukraine",
        "nationality": "Ukrainian"
    },
    {
        "num_code": "784",
        "alpha_2_code": "AE",
        "alpha_3_code": "ARE",
        "name": "United Arab Emirates",
        "nationality": "Emirati, Emirian, Emiri"
    },
    {
        "num_code": "826",
        "alpha_2_code": "GB",
        "alpha_3_code": "GBR",
        "name": "United Kingdom of Great Britain and Northern Ireland",
        "nationality": "British, UK"
    },
    {
        "num_code": "581",
        "alpha_2_code": "UM",
        "alpha_3_code": "UMI",
        "name": "United States Minor Outlying Islands",
        "nationality": "American"
    },
    {
        "num_code": "840",
        "alpha_2_code": "US",
        "alpha_3_code": "USA",
        "name": "United States of America",
        "nationality": "American"
    },
    {
        "num_code": "858",
        "alpha_2_code": "UY",
        "alpha_3_code": "URY",
        "name": "Uruguay",
        "nationality": "Uruguayan"
    },
    {
        "num_code": "860",
        "alpha_2_code": "UZ",
        "alpha_3_code": "UZB",
        "name": "Uzbekistan",
        "nationality": "Uzbekistani, Uzbek"
    },
    {
        "num_code": "548",
        "alpha_2_code": "VU",
        "alpha_3_code": "VUT",
        "name": "Vanuatu",
        "nationality": "Ni-Vanuatu, Vanuatuan"
    },
    {
        "num_code": "862",
        "alpha_2_code": "VE",
        "alpha_3_code": "VEN",
        "name": "Venezuela (Bolivarian Republic of)",
        "nationality": "Venezuelan"
    },
    {
        "num_code": "704",
        "alpha_2_code": "VN",
        "alpha_3_code": "VNM",
        "name": "Vietnam",
        "nationality": "Vietnamese"
    },
    {
        "num_code": "92",
        "alpha_2_code": "VG",
        "alpha_3_code": "VGB",
        "name": "Virgin Islands (British)",
        "nationality": "British Virgin Island"
    },
    {
        "num_code": "850",
        "alpha_2_code": "VI",
        "alpha_3_code": "VIR",
        "name": "Virgin Islands (U.S.)",
        "nationality": "U.S. Virgin Island"
    },
    {
        "num_code": "876",
        "alpha_2_code": "WF",
        "alpha_3_code": "WLF",
        "name": "Wallis and Futuna",
        "nationality": "Wallis and Futuna, Wallisian or Futunan"
    },
    {
        "num_code": "732",
        "alpha_2_code": "EH",
        "alpha_3_code": "ESH",
        "name": "Western Sahara",
        "nationality": "Sahrawi, Sahrawian, Sahraouian"
    },
    {
        "num_code": "887",
        "alpha_2_code": "YE",
        "alpha_3_code": "YEM",
        "name": "Yemen",
        "nationality": "Yemeni"
    },
    {
        "num_code": "894",
        "alpha_2_code": "ZM",
        "alpha_3_code": "ZMB",
        "name": "Zambia",
        "nationality": "Zambian"
    },
    {
        "num_code": "716",
        "alpha_2_code": "ZW",
        "alpha_3_code": "ZWE",
        "name": "Zimbabwe",
        "nationality": "Zimbabwean"
    }
]
export const TicketStatusObject = {
    0: "Failed",
    1: "Successful",
    2: "NotSaved",
    3: "NotCreated",
    4: "NotAllowed",
    5: "InProgress",
    6: "TicketAlreadyCreated",
    8: "PriceChanged",
    9: "OtherError",
}
export const ReleaseStatusObject = {
    0: "NotSet",
    // 1: "Successful",
    1: "Released",
    2: "Failed",
    3: "In Valid Request",
    4: "In Valid Session",
    5: "In Valid Credentials"
}
export const BookingStatusObject = {
    0: "Not Set",
    // 1: "Successful",
    1: "Hold",
    2: "Failed",
    3: "Other Fare",
    4: "Other Class",
    5: "Booked Other",
    6: "Not Confirmed"
}

export const FilterStatusObject = {

    // 1: "Successful",
    1: "Successful",
    2: "Hold",
    3: "Released",
    0: "Failed",
}

export const statusOptions = Object.keys(FilterStatusObject).map((key) => ({
    label: FilterStatusObject[key],
    value: key
}));

export const formatCurrency = (amount, currency = 'INR', locale = 'hi-IN') => {
    return new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currency
    }).format(amount);
}


export const CancellationRequestTypes = [
    {name: "Refund With Airline Penalty", value: 1},
    {name: "Partial Cancellation", value: 2},
    {name: "Flight Not Operational", value: 0},
    {name: "Change Itinerary/Re-Issue", value: 3},
];


export const ReissueRequestTypes = [
    {name: "Reissue with Available Fare", value: 1},
    {name: "Manual ReIssue", value: 2},
    // {name: "Reissue by class", value: 3}
]
export const getAirlineLogo = (airlineCode) => {
    let logo = `https://x-travel-world.s3.us-east-1.amazonaws.com/airline-logos/airline-logos/${airlineCode}.gif`
    return logo;
}
export const calculateAge = (birthYear, birthMonth, birthDay) => {
    let today = new Date();
    let birthDate = new Date(birthYear, birthMonth, birthDay); // Month is 0-based in JS

    let age = today.getFullYear() - birthDate.getFullYear();
    let monthDiff = today.getMonth() - birthDate.getMonth();
    let dayDiff = today.getDate() - birthDate.getDate();

    // Adjust age if the birthday hasn't occurred yet this year
    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
        age--;
    }

    return age;
}

export const calculateAgeInDecimal = (birthYear, birthMonth, birthDay) => {
    let birthDate = new Date(birthYear, birthMonth - 1, birthDay); // Month is 0-based in JS
    const birth = new Date(birthDate);
    const today = new Date();

    const ageInMilliseconds = today - birth;
    const millisecondsInYear = 1000 * 60 * 60 * 24 * 365.25; // Accounts for leap years

    return (ageInMilliseconds / millisecondsInYear).toFixed(2); // Returns age up to 6 decimal places
}
export const getTitleListFxn = (paxType) => {
    let titleArr = [
        {name: "Mr", gender: 1},
        {name: "Ms", gender: 2}
    ]
    if (paxType == 3) {
        titleArr = [{name: "Mstr", gender: 1}, {name: "Ms", gender: 2}]
    } else if (paxType == 1) {
        titleArr.push({name: "Mrs", gender: 2})
    }
    return titleArr
}
export const userTypesObj = {
    agent: ["agent"],
    admin: ['admin', 'itTeam'],
    team: ['xTravelManager'],
}

export const DefaultTablePagination = (
    newParams,
    defaultPageSize = 10,
    pageSizeOptions = ['10', '20', '50'],
    position = 'top'
) => {
    let params = {
        defaultPageSize,
        pageSizeOptions,
        ...newParams,
        position: position
    }
    return params
}

export const RequestStatus = {
    "1": "Confirmed",
    "2": "Not Confirmed",
}

export const CancellationRequest = {
    "1": "Refund With Airline Penalty",
    "2": "Partial Cancellation",
    "0": "Flight Not Operational",
    "3": "Change Itinerary/Re-Issue",
}
export const GetStatusBox = (ResponseStatus) => {
    const statusStyles = {
        1: {
            backgroundColor: "#96A53A",
            color: "white",
            padding: "5px 10px",
            borderRadius: "4px",
            display: "inline-block"
        },
        2: {
            backgroundColor: "#F7CB73",
            color: "black",
            padding: "5px 10px",
            borderRadius: "4px",
            display: "inline-block"
        },
    };

    return (
        <div style={statusStyles[ResponseStatus] || {padding: "5px 10px", borderRadius: "4px"}}>
            {RequestStatus[ResponseStatus]}
        </div>
    );
};

export const defaultInitState = {
    origin: "DEL",
    originCity: "Delhi",
    originAirportName: "Indira Gandhi Airport",
    destination: "BLR",
    destinationCity: "Bangalore",
    destinationAirportName: "Kempegowda International Airport",
}

export const getArrivalDays = (segments) => {
    let cloneSegment = _.cloneDeep(segments)
    try {
        let firstSegment = cloneSegment[0];
        let lastSegment = cloneSegment.pop();
        const startTime = firstSegment?.['Origin']?.['DepTime'];
        const endTime = lastSegment?.['Destination']?.['ArrTime'];
        let startDay = moment(startTime).date()
        let endDay = moment(endTime).date()
        return ((endDay - startDay) || 0)
    } catch (e) {
        return (null)
    }
}


export const calculateLayoverTime = (arrivalTime, departureTime) => {
    const arr = new Date(arrivalTime);
    const dep = new Date(departureTime);
    const diffMinutes = Math.max(0, Math.floor((dep - arr) / 60000));
    return diffMinutes;
};

export const getTotalDuration = (segments) => {
    let totalDuration = 0;

    segments.forEach((segment, index) => {

        if (segment.Duration) {
            totalDuration += parseFloat(segment.Duration);
        }

        const nextSegment = segments[index + 1];
        if (nextSegment) {
            const layoverTime = calculateLayoverTime(segment.Destination?.ArrTime, nextSegment.Origin?.DepTime);
            totalDuration += layoverTime;
        }
    });

    return minToHours(totalDuration);
};

export const getTotalDurationSegments = (segments) => {
    console.log(segments, 'get duration function');
    const allSegments = segments?.flat();
    let firstSegment = allSegments?.[0]
    let lastSegment = allSegments[allSegments?.length - 1]
    let totalDuration = 0;
    let segmentDuration = 0;


    allSegments.forEach((segment, index) => {
        if (segment.Duration) {
            totalDuration += parseFloat(segment.Duration);
            segmentDuration += parseFloat(segment.Duration);
        }
        const nextSegment = allSegments[index + 1];
        if (nextSegment) {
            const layoverTime = calculateLayoverTime(segment.Destination?.ArrTime, nextSegment.Origin?.DepTime);
            totalDuration += layoverTime;
        }
    });

    if (segmentDuration == 0) {
        if (lastSegment['AccumulatedDuration']) {
            return minToHours(lastSegment['AccumulatedDuration']);
        } else {
            return minToHours(firstSegment['Duration']);
        }
    } else {
        return minToHours(totalDuration);
    }
};
export const getTotalDurationValueSegments = (segments) => {
    const allSegments = segments.flat();
    let firstSegment = allSegments[0]
    let lastSegment = allSegments[allSegments.length - 1]
    if (lastSegment['AccumulatedDuration']) {
        return lastSegment['AccumulatedDuration'];
    } else {
        return firstSegment['Duration'];
    }
};
export const getTotalDurationFromSegments = (segmentStarting, segmentEnding) => {
    const firstSegmentDepTime = new Date(segmentStarting.Origin.DepTime);
    const lastSegmentArrTime = new Date(segmentEnding.Destination.ArrTime);
    const totalMinutes = Math.floor((lastSegmentArrTime - firstSegmentDepTime) / (1000 * 60));
    return minToHours(totalMinutes);
};
export const managerInfo = {
    name: "Naman Bajaj",
    email: "naman.bajaj@xtravelworld.com",
    mobile: "+91 99883 38922",
}

export const calculateNewFareAllMargins = (fare, marginData) => {
    // console.log(fare, "---------sadsf---");
    // console.log(marginData, "-----sdfdsg-------");

    let oldPublishFare = parseFloat(fare?.PublishedFare) || 0;
    // console.log(fare?.PublishedFare, "000000000");

    const totalTax = ((fare?.OtherCharges || 0) / 1.18).toFixed(2);
    const totalGST = totalTax * 0.18;

    let fareWithoutGst = oldPublishFare - totalGST;

    let xTravelExchangeMargin =
        marginData?.agentXTravelMargin !== undefined
            ? marginData?.agentXTravelMargin
            : marginData?.xTravelExchangeMargin;

    let xTravelMarginPercentage = parseFloat(xTravelExchangeMargin) || 0;
    let xTravelMargin = fareWithoutGst * (xTravelMarginPercentage / 100);

    let gstOnXTravelMargin = xTravelMargin ? xTravelMargin * 0.18 : 0;

    // console.log(fareWithoutGst, xTravelMargin, gstOnXTravelMargin, "margins");

    return {
        newFare: Math.round(fareWithoutGst + xTravelMargin + gstOnXTravelMargin),
        fareWithoutGst,
        xTravelMargin,
        totalGST,
        gstOnXTravelMargin
    };
};

export const calculateNewFareWithMargins = (fare, marginData) => {

    let oldPublishFare = parseFloat(fare?.PublishedFare) || 0;
    const totalTax = ((fare?.OtherCharges || 0) / 1.18).toFixed(2);
    const totalGST = totalTax * 0.18;

    let fareWithoutGst = oldPublishFare - totalGST

    let xTravelExchangeMargin =
        marginData?.agentXTravelMargin != undefined ?
            marginData?.agentXTravelMargin :
            marginData?.xTravelExchangeMargin

    let xTravelMarginPercentage = parseFloat(xTravelExchangeMargin) || 0;
    let xTravelMargin = fareWithoutGst * (xTravelMarginPercentage / 100);

    let gstOnXTravelMargin = xTravelMargin ? xTravelMargin * 0.18 : 0;
    return Math.round(
        fareWithoutGst +
        xTravelMargin +
        gstOnXTravelMargin
    );
};

export const updatePriceUpdate = async (data, marginData) => {
    if (!Array.isArray(data) || data.length === 0) {
        return data;
    }

    return data.map((item) => ({
        ...item,
        Fare: {
            ...item.Fare,
            newFare: calculateNewFareWithMargins(item.Fare, marginData),
        },
    }));
};

export const updateSingleObjectPriceUpdate = async (data, marginData) => {
    if (!data || typeof data !== 'object') {
        return data;
    }

    return {
        ...data,
        Fare: {
            ...data.Fare,
            newFare: calculateNewFareWithMargins(data.Fare, marginData),
        },
    };
};

export const longDisplayDate = date => {
    if (date) {
        return moment(date).format('DD.MM.YYYY,h:mm A')
    } else {
        return null
    }
}

export const numberToWords = (num) => {
    const singleDigits = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine']
    const teens = ['Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen']
    const tens = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety']
    const thousands = ['', 'Thousand', 'Lakh', 'Crore']

    function getWords(n) {
        if (n < 10) return singleDigits[n]
        if (n < 20) return teens[n - 10]
        if (n < 100) return tens[Math.floor(n / 10)] + ' ' + singleDigits[n % 10]
        if (n < 1000) return singleDigits[Math.floor(n / 100)] + ' Hundred ' + getWords(n % 100)
        for (let i = 0; i < thousands.length; i++) {
            const divisor = Math.pow(1000, i + 1)
            if (n < divisor) return getWords(Math.floor(n / Math.pow(1000, i))) + ' ' + thousands[i] + ' ' + getWords(n % Math.pow(1000, i))
        }
    }

    try {
        const [rupees, paise] = num.toFixed(2).split('.')
        let words = ''

        if (rupees && parseInt(rupees) > 0) {
            words += getWords(parseInt(rupees)) + ' Rupees'
        }

        if (paise && parseInt(paise) > 0) {
            if (words) words += ' and '
            words += getWords(parseInt(paise)) + ' Paise'
        }
        return words || 'Zero Rupees'
    } catch (e) {
        return ''
    }

}
export const isValidEmail = (email) => {
    email = email.trim();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}
export const formatRupees = (amount) => {
    /*  return new Intl.NumberFormat('en-IN', {
          style: 'currency',
          currency: 'INR',
          minimumFractionDigits: 2,
      }).format(amount);*/

    return new Intl.NumberFormat("en-IN", {
        maximumFractionDigits: 0,
        useGrouping: true,
    }).format(amount);
};
