import {Card, Icon, Modal} from "../../../components/Elements";
import React, {useEffect, useState} from "react";
import SinglePassCard from "./SinglePassCard";
import AncillaryServices from "./AncillaryServices";
import ContactDetailsComponent from "./ContactDetailsComponent";
import GstDetailsComponent from "./GstDetailsComponent";
import TickIcon from '../../../assets/img/tick.png'
import ExclamationIcon from '../../../assets/img/exclamation.png'
import moment from "moment";
import BookedImg from '../../../assets/img/booked.png'
import FareRulesModal from "../../flightSearchResult/components/fareRulesModal";

const flightDate = (date) => {
    return moment(date).format("dddd, MMMM, Do, YYYY")
}
const ReviewBookingComponent = (props) => {
    let {passengerState = {}, selectedFlight, callbackState, flightObj, bookState, backtosearch} = props;
    // console.log(props);
    let {
        adultPassengers = [],
        childPassengers = [],
        infantPassengers = [],
        state = {},
        gstState = {},
        ancillaryServices
    } = passengerState;
    const [showModal, setShowModal] = useState({
        visible: false,
    });


    const [isTermsAccepted, setIsTermsAccepted] = useState(false);
    return (
        <>
            <Card title={
                <div className={'d-flex justify-content-between align-items-center'}>
                    <span>
                                            Passengers

                    </span>
                    <button
                        className={'btn'}
                        onClick={backtosearch}
                    >
                        <Icon name="edit"/>
                    </button>
                </div>
            } round={12}>
                {adultPassengers && adultPassengers.map((item, index) => {
                    return <SinglePassCard title={'ADULT'} index={index} data={item} state={state}/>
                })}
                {childPassengers && childPassengers.map((item, index) => {
                    return <SinglePassCard title={'CHILD'} index={index} data={item} state={state}/>
                })}
                {infantPassengers && infantPassengers.map((item, index) => {
                    return <SinglePassCard title={'INFANT'} index={index} data={item} state={state}/>
                })}
            </Card>

            {adultPassengers || childPassengers ?
                <>
                    <div className="flight-accordion-details mt-3">
                        <AncillaryServices
                            adultPassenger={adultPassengers}

                            childPassenger={childPassengers}
                            infantPassenger={infantPassengers}
                            services={ancillaryServices}
                            data={selectedFlight}
                            contactState={state}
                            gstState={gstState}
                            backtosearch={backtosearch}
                        />
                    </div>
                </> : null}

            <div className="flight-accordion-details mt-3">
                <ContactDetailsComponent
                    childPassengers={childPassengers}
                    data={selectedFlight}
                    contactState={state}
                    gstState={gstState}
                    adultPassengers={adultPassengers}
                />
            </div>
            <div className="flight-accordion-details mt-3">
                <GstDetailsComponent
                    childPassengers={childPassengers}
                    data={selectedFlight}
                    contactState={state}
                    gstState={gstState}
                    adultPassengers={adultPassengers}
                />
            </div>


            <div className="flight-accordion-details mt-3">
                <div className="accordion" id="accordionPanelsStayOpenExample">
                    <div className="accordion-item">
                        <h2 className="accordion-header d-flex align-items-center"
                            id="panelsStayOpen-headingOne">
                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <FareRulesModal traceId={props.traceId} flight={selectedFlight}>
                                        <button className="nav-link active">
                                            Fare Rules
                                        </button>
                                    </FareRulesModal>
                                </li>
                            </ul>

                        </h2>
                        <div
                            id="panelsStayOpen-collapseOne"
                            className="accordion-collapse collapse show"
                            aria-labelledby="panelsStayOpen-headingOne">

                            <div className="accordion-body">
                                <div className="tab-content" id="myTabContent">
                                    <div
                                        className="tab-pane fade active show"
                                        id="fare-rules-tab-pane"
                                        role="tabpanel"
                                        aria-labelledby="fare-rules-tab"
                                        tabIndex="0">
                                        <div className="flight-custom-table">
                                            <div className="flight-destimation-time">
                                                <div className="row row-gap-3 align-items-center">
                                                    <div className="col-lg-4">
                                                        <div className="d-flex align-items-center"
                                                             style={{gap: '30px'}}>
                                                            <h6 className="m-0 white">{flightObj.origin}</h6>
                                                            <span><img src="../images2/plane.png" width="22"
                                                                       alt="plane"/></span>
                                                            <h6 className="m-0 white">{flightObj.destination}</h6>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <p className="m-0">on {flightDate(flightObj.preferredDepartureTime)}</p>
                                                    </div>
                                                    {/*<div className="col-lg-4">*/}
                                                    {/*    <p className="m-0">21hr 10min</p>*/}
                                                    {/*</div>*/}
                                                </div>
                                            </div>

                                            <div className="content-box mt-3">
                                                <ul>
                                                    <small
                                                        style={{fontSize: "14px"}}
                                                        className="mb-3 d-block">
                                                        * To view charges, see the fee sections below.
                                                    </small>
                                                    {selectedFlight?.MiniFareRules?.[0]?.map((rule, index) => (
                                                        <li key={index}>
                                                            <span><b>{rule.Type}</b></span>
                                                            <span>
                                                            {rule.Details} <br/>
                                                            From {rule.From || "0"} {rule.Unit}
                                                                {rule.To ? ` to ${rule.To} ${rule.Unit}` : ` before departure`}
                                                                for {rule.JourneyPoints}.
                                                        </span>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                            <div className="d-flex rules-icon mt-3">
                                                    <span>
                                                         <img src={ExclamationIcon} alt={'icon'}
                                                              style={{height: 18, marginRight: 5}}/>Important</span>
                                            </div>
                                            <div className="rules d-flex flex-wrap flex-lg-nowrap gap-3">
                                                <ul className="w-100">
                                                    <li>
                                                        MENTIONED FEE ARE INDICATIVE PER PAX AND PER SECTOR.
                                                    </li>
                                                    <li>APART FROM AIRLINE CHARGES,GST+RAF+ APPLICABLE CHARGES IF ANY,
                                                        WILL BE CHARGED..
                                                    </li>
                                                    <li> FOR DOMESTIC BOOKINGS, PASSENGERS ARE REQUIRED TO SUBMIT THE
                                                        CANCELLATION OR REISSUE REQUEST AT LEAST 2 HOURS BEFORE THE
                                                        AIRLINES CANCELLATION AND REISSUE POLICY. .
                                                    </li>
                                                    <li>
                                                        FOR INTERNATIONAL BOOKINGS, PASSENGERS ARE REQUIRED TO SUBMIT
                                                        THE CANCELLATION OR REISSUE REQUEST AT LEAST 4 HOURS BEFORE THE
                                                        AIRLINES CANCELLATION AND REISSUE POLICY.

                                                    </li>
                                                </ul>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={'row mt-3'}>

                <div className={'col-md-5'}>
                    <div className="terms-check d-flex flex-wrap gap-2 gap-lg-5 flex-lg-nowrap mt-3 align-items-center">
                        <div className="d-flex gap-2 text-nowrap align-items-center">
                            <div className="form-check">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id={`acceptTerms`}
                                    checked={isTermsAccepted}
                                    onChange={() => setIsTermsAccepted(!isTermsAccepted)}

                                />
                                <label className="form-check-label ms-2 mt-1" htmlFor={`acceptTerms`}> I accept <a
                                    href="#">terms & conditions</a></label>
                            </div>
                            {/*            <input*/}
                            {/*                type="checkbox"*/}
                            {/*                id="acceptTerms"*/}
                            {/*                checked={isTermsAccepted}*/}
                            {/*                onChange={(e) => setIsTermsAccepted(e.target.checked)}*/}
                            {/*                style={{display: "none"}}*/}
                            {/*            />*/}
                            {/*            <span onClick={() => setIsTermsAccepted(!isTermsAccepted)} style={{cursor: "pointer"}}>*/}
                            {/*  <img src={TickIcon} alt={'icon'}*/}
                            {/*       style={{width: '22px'}}*/}
                            {/*  />*/}
                            {/*</span>*/}
                            {/*            <label htmlFor="acceptTerms">*/}
                            {/*                I accept <a href="#">terms & conditions</a>*/}
                            {/*            </label>*/}
                        </div>
                    </div>

                </div>

                <div className={'col-md-3 mt-3'}>
                    {selectedFlight?.IsLCC === false && (
                        <button
                            className="btn btn-dark w-100"
                            onClick={bookState}
                            disabled={!isTermsAccepted}
                        >
                            Hold
                        </button>
                    )}
                    {selectedFlight?.LastTicketDate ? `Pnr Limit - ${moment(selectedFlight?.LastTicketDate).format('DD-MMM-YYYY')}` : ''}

                </div>
                <div className={'col-md-4 mt-3'}>
                    <button className="btn btn-primary ms-3 w-100" onClick={callbackState} disabled={!isTermsAccepted}>
                        Proceed To Pay
                    </button>
                </div>
            </div>
            {showModal.visible && (
                <Modal
                    visible={showModal.visible}
                    className={'popup-confirm'}
                    width={'38%'}
                    onClose={() => setShowModal({...showModal, visible: false})}
                >
                    <div className={'mt-1 alert-popup'}>
                        <div className={'mb-5'}>
                            <img src={BookedImg} alt={'airportimg'} style={{width: '24%'}}/>
                        </div>
                        <h3 className={'mb-4'}>Your flight tickets have been booked!</h3>
                        <div className="d-flex justify-content-center gap-3 mb-2">
                            <button
                                className="customYesBtn"
                                onClick={bookState}
                                // onClick={() => navigate('/response')}
                            >
                                Continue
                            </button>


                        </div>

                    </div>
                </Modal>
            )}
        </>
    )
}
export default ReviewBookingComponent
