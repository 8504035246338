import React, {useEffect} from "react"
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import CheckoutWithStopComponent from "./flightListcomponents/checkoutWithStopComponent";
import {displayDate, getAirlineLogo, getTotalDuration, getTotalDurationSegments} from "../../components/utils/appUtils";
import FlightImg from "../../components/flightImg";
import AllStopsDetailsPopover from "./components/AllStopsDetailsPopover";
import LccStatus from "./components/LccStatus";


const FlightAddress = ({data}) => {
    return (
        <>
            <small className="d-block">
                {/*{data && data.Airport && data.Airport.AirportName ? data.Airport.AirportName : ""}*/}

            </small>
            <small className="d-block">
                {/*{data && data.Airport && data.Airport.Terminal ? `${data.Airport.Terminal}, ` : ""}*/}
                {data && data.Airport && data.Airport.CityName ? `${data.Airport.CityName} ` : ""}
                {data && data.Airport && data.Airport.AirportCode ? ` (${data.Airport.AirportCode}) ` : ""}

                {/*{data && data.Airport && data.Airport.CountryName ? data.Airport.CountryName : ""}*/}
            </small>
        </>
    )
}
const NonStopComponent = (props) => {
    let {data} = props;
    let {Segments, FareRules: fareRules} = data;
    let allSegmentData = Segments[0];
    let segmentStarting = allSegmentData[0];
    let segmentEnding = allSegmentData[allSegmentData.length - 1];
    return (
        <>
            <div className={''}>
                <ul>
                    <li className="p-0">
                          <span className="logo">
                            <img src={getAirlineLogo(data.AirlineCode)} alt=""/>
                          </span>
                        <h5>
                            {segmentStarting && segmentStarting.Airline && segmentStarting.Airline.AirlineName}
                            {segmentStarting ? <small className="d-block">
                                {segmentStarting.Airline && segmentStarting.Airline.AirlineCode ? segmentStarting.Airline.AirlineCode : ""}&nbsp;-&nbsp;
                                {segmentStarting.Airline && segmentStarting.Airline.FlightNumber ? segmentStarting.Airline.FlightNumber : ""}&nbsp;&nbsp;
                                {segmentStarting.Airline && segmentStarting.Airline.FareClass ? segmentStarting.Airline.FareClass : ""}
                            </small> : null}
                            <LccStatus data={data}/>
                        </h5>
                        <div className="d-flex align-items-center" style={{gap: 30}}>
                            <h5>
                                {segmentStarting.Origin && segmentStarting.Origin.DepTime ? <>
                                    {displayDate(segmentStarting.Origin.DepTime)}
                                </> : null}
                                {segmentStarting.Origin ? <FlightAddress data={segmentStarting.Origin}/> : null}
                            </h5>
                            <span><FlightImg/></span>
                            <h5>
                                <small className="d-block">
                                    {getTotalDurationSegments(allSegmentData)}
                                </small>
                                <small className={"d-block"}>
                                    <p className={'non-stop-p'}>Non-Stop</p>

                                </small>

                            </h5>
                            <span><FlightImg/></span>

                            <h5>
                                {segmentEnding.Destination && segmentEnding.Destination.ArrTime ? <>
                                    {displayDate(segmentEnding.Destination.ArrTime)}
                                </> : null}
                                {segmentEnding.Destination ?
                                    <FlightAddress data={segmentEnding.Destination}/> : null}
                            </h5>
                            <h5>
                                {getTotalDurationSegments(allSegmentData)}
                                <small className="d-block">Economy Class</small>
                                <small className="d-block">
                                    {data.IsRefundable ? "Refundable" : "Non Refundable"}
                                </small>
                            </h5>
                        </div>
                    </li>
                    <p className="line">
                        Adult: Check-in (
                        <span style={{ color: segmentStarting?.Baggage === '0' ? 'red' : 'inherit' }}>
                            {segmentStarting?.Baggage}
                        </span>) + Cabin Baggage: {segmentStarting?.CabinBaggage}
                    </p>

                </ul>
            </div>
        </>
    )
}
const OneWayFlightInfoBox = (props) => {
    let {data, journeyType, title, bookingType = "", sessionId = ""} = props;
    let {Segments} = data;

    let allSegmentData = Segments[0];
    let checkWhichProcess = journeyType == "2" ? bookingType == title : true;
    const compD = (
        allSegmentData.length > 1 ? <CheckoutWithStopComponent {...props}/> : <NonStopComponent {...props}/>
    )
    const navigate = useNavigate();
    const dispatch = useDispatch()

    const checkoutInBound = () => {
        navigate(`/checkout`, {state: {params: {bookingType: "Inbound", sessionId}}});
    }


    return (
        <div className={`flight-info-box`}>
            {!checkWhichProcess ? <div className={'disabled-block'}></div> : null}

            {sessionId && title == "Inbound" && !checkWhichProcess ?
                <div className={'book-flight-block'}>
                    <a onClick={checkoutInBound}>Click here to Inbound booking</a>
                </div> : null}

            <div className={`track-flights ${!checkWhichProcess && "opacity-75"}`}>
                {journeyType == "2" ?
                    <div>
                        <div className={'flight-type-box'}>
                            <h5>{title}</h5>
                            <span>
                                {checkWhichProcess ? "In Process" : ""}
                            </span>
                        </div>
                        {compD}
                    </div> :
                    <div>
                        {compD}
                    </div>
                }
            </div>
        </div>
    )
}
export default OneWayFlightInfoBox
