import React, {useState} from "react"
import {Modal} from "../../../components/Elements"
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import AirportImg from '../../../assets/img/airport.png'
import {OverseasWithStopComponents, OverseasNonStopComponents} from "./overseasStopsComponents";
import FlightFareIconsComponent from "../components/flightFareIconsComponent";
import {CabinObject, getArrivalDays} from "../../../components/utils/appUtils";

const OverseasFlightAddress = (props) => {
    let {data, flightInfo} = props;

    const [showModal, setShowModal] = useState({
        visible: false,
        searchDestination: '',
        flightDestination: ''
    });
    const navigate = useNavigate()
    const dispatch = useDispatch()
    // console.log(data, "dttttt")

    const events = {
        confirmBooking: async (data) => {
            if (!data?.Segments || data.Segments.length === 0) {
                return;
            }

            const searchOrigin = flightInfo.origin;
            const searchDestination = flightInfo.destination;
            const flightOrigin = data?.Segments?.[0]?.[0]?.Origin?.Airport?.AirportCode;
            const firstSegment = data?.Segments?.[0];

            if (!firstSegment || firstSegment.length === 0) {
                return;
            }
            const flightDestination = firstSegment?.[firstSegment?.length - 1]?.Destination?.Airport?.AirportCode;
            if (searchDestination !== flightDestination) {
                setShowModal({
                    visible: true,
                    searchDestination: `${searchOrigin}-${searchDestination}`,
                    flightDestination: `${flightOrigin}-${flightDestination}`,
                });
            } else {
                dispatch({
                    type: "UPDATE_RESULT_INDEX",
                    selectedFlight: {...data, FareBasisCode: {}},
                    traceId: flightInfo.traceId,
                    flightBookingType: "overseasFlight"
                });
                navigate("/checkout");
            }
        }
    }
    const handleModalAction = (action) => {
        if (action === "yes") {
            setShowModal({...showModal, visible: false});
            dispatch({
                type: "UPDATE_RESULT_INDEX",
                selectedFlight: {...data, FareBasisCode: {}},
                traceId: flightInfo.traceId,
                flightBookingType: "overseasFlight"
            });
            navigate("/checkout");
        } else {
            setShowModal({...showModal, visible: false});
        }
    };
    const calculateArrivalDays = (segments) => {
        if (!segments || segments.length === 0 || !segments[0] || segments[0].length === 0) return "N/A";

        const firstSegment = segments[0][0];
        const lastSegment = segments[0][segments[0].length - 1];
        if (!firstSegment || !lastSegment) return "N/A";

        const departureDate = new Date(firstSegment.Origin.DepTime);
        const arrivalDate = new Date(lastSegment.Destination.ArrTime);

        const depDateOnly = new Date(departureDate.getFullYear(), departureDate.getMonth(), departureDate.getDate());
        const arrDateOnly = new Date(arrivalDate.getFullYear(), arrivalDate.getMonth(), arrivalDate.getDate());

        const timeDifference = arrDateOnly - depDateOnly;

        const dayDifference = timeDifference / (1000 * 60 * 60 * 24);

        return dayDifference >= 1 ? `${dayDifference} day${dayDifference > 1 ? "s" : ""}` : "same day";
    };


    const arrivalDays = data?.Segments?.length ? calculateArrivalDays(data.Segments) : "N/A";
    const arrivalDayValue = data?.Segments?.[0] ? getArrivalDays(data.Segments[0]) : "N/A";
    const seatsAvailable = data?.Segments?.[0]?.[0]?.NoOfSeatAvailable || 0;

    return (
        data && Array.isArray(data['Segments']) && data['Segments'].length > 0 ? (
            <>

                <div className="book-flight mb-3">
                    <div className="row row-gap-3">
                        <div className="col-lg-10">
                            <div>
                                <div className="track-flights">
                                    { data?.Segments && data.Segments.length > 0 ? (
                                        <OverseasWithStopComponents {...props} allSegmentData={data['Segments'][0]}/>
                                    ) : (
                                        <OverseasNonStopComponents {...props} allSegmentData={data['Segments'][0]}/>
                                    )}
                                    <div className={'mt-2'}>
                                        <FlightFareIconsComponent
                                            flight={data}
                                            bookingType={"outBound"}
                                            traceId={flightInfo.traceId}
                                        />
                                    </div>

                                    {/*<div className="class-price mt-2">*/}
                                    {/*    <div className="row">*/}
                                    {/*        <div className="row row-gap-3">*/}

                                    {/*                    <div className="col-lg-6">*/}
                                    {/*                        <div className="main-box">*/}
                                    {/*                            <input type="radio" name={`class-price-`}*/}
                                    {/*                                   className="class-price"*/}
                                    {/*                                // checked={item['ResultIndex'] == flightData['ResultIndex']}*/}
                                    {/*                                // onChange={() => handleFlightSelection(item)}*/}
                                    {/*                            />*/}
                                    {/*                            <div className="box">*/}
                                    {/*                                <span></span>*/}
                                    {/*                                <div>*/}
                                    {/*                                    {*/}
                                    {/*                                        data['Segments'][0][0]['FareClassification'] &&*/}
                                    {/*                                        <div className="fare-classification">*/}
                                    {/*                                            /!*{item['FareClassification']?.Type}*!/*/}
                                    {/*                                        </div>*/}
                                    {/*                                    }*/}
                                    {/*                                    <small>{CabinObject[data['Segments'][0][0]['CabinClass']] || 'Economy'}*/}
                                    {/*                                    </small>*/}
                                    {/*                                    <h5>Rs. {data['Segments'][0][0]['Fare'] && data['Segments'][0][0]['Fare'].PublishedFare ? data['Segments'][0][0]['Fare'].PublishedFare : ""}</h5>*/}
                                    {/*                                </div>*/}
                                    {/*                            </div>*/}

                                    {/*                        </div>*/}
                                    {/*                        /!*<FlightFareIconsComponent flight={item}*!/*/}
                                    {/*                        /!*                          bookingType={type == "outBound" ? "Outbound" : "Inbound"}*!/*/}
                                    {/*                        /!*                          traceId={flightInfo.traceId}/>*!/*/}

                                    {/*                    </div>*/}


                                    {/*        </div>*/}


                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </div>

                            </div>

                            {/* Check if return flight data exists before accessing index [1] */}
                            {data['Segments'][1] && (
                                <div className={'mt-2'}>
                                    <div className="track-flights overseas-return-track">
                                        {data['Segments'][1]?.length > 1 ? (
                                            <OverseasWithStopComponents {...props}
                                                                        allSegmentData={data['Segments'][1]}/>
                                        ) : (
                                            <OverseasNonStopComponents {...props} allSegmentData={data['Segments'][1]}/>
                                        )}
                                        <div className={'mt-2'}>
                                            <FlightFareIconsComponent
                                                flight={data}
                                                bookingType={"Inbound"}
                                                traceId={flightInfo.traceId}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className="col-lg-2">
                            <div className="d-flex flex-wrap gap-3 h-100">
                                <div className="total-price">
                                    <span>Total Price</span>
                                    <h5>Rs. {data?.Fare?.newFare || "N/A"}</h5>
                                    <button
                                        onClick={() => events.confirmBooking(data)}
                                        className="btn btn-primary">
                                        Book Now
                                    </button>
                                </div>

                                {arrivalDayValue ? (
                                    <div className="total-price cursor-pointer">
                                        <span>Flight Arrives in {arrivalDayValue} {arrivalDayValue > 1 ? "Days" : "day"}
                                        </span>
                                    </div>
                                ) : null}


                                {seatsAvailable > 0 && (
                                    <div className="total-price cursor-pointer">
                                    <span className="ms-2" style={{color: "red"}}>
                                            Seats left: {seatsAvailable}
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>

                        {data.IsTransitVisaRequired && (
                            <small className={'d-block mt-2'}>
                                <span className={'color-red'}>   Transit Visa is Required </span>
                            </small>
                        )}
                    </div>
                </div>

                {/* Modal for different airport alert */}
                {showModal.visible && (
                    <Modal
                        visible={showModal.visible}
                        className={'popup-confirm'}
                        width={'38%'}
                        onClose={() => setShowModal({...showModal, visible: false})}>
                        <div className={'mt-1 alert-popup'}>
                            <div className={'mb-4'}>
                                <img src={AirportImg} alt={'airportimg'} width={'30%'}/>
                            </div>
                            <h3 className={'mb-4'}>Different Airport Alert</h3>
                            <p className={'mb-4'}>
                                You have searched for Outbound <strong>({showModal.searchDestination})</strong>,
                                however, this flight operates from
                                Outbound <strong>({showModal.flightDestination})</strong>.
                            </p>
                            <h3 className={'mb-4'}>Do you wish to continue?</h3>
                            <div className="d-flex justify-content-center gap-3 mb-2">
                                <button className="customYesBtn" onClick={() => handleModalAction("yes")}>Yes</button>
                                <button className="customNoBtn" onClick={() => handleModalAction("no")}>No</button>
                            </div>
                        </div>
                    </Modal>
                )}
            </>
        ) : null
    );

}

export default OverseasFlightAddress
