import moment from "moment";
import React, {useEffect, useState} from "react";
import {Radio} from "../../../components/Elements";
import {defaultInitState, flightTypeOption} from "../../../components/utils/appUtils";
import RoundTripSearchComponent from "./roundTripSearchComponent";
import {searchPatternBg} from "../../../components/imgComp"
import MultiCitySearchComponent from "./multiCitySearchComponent";

const initState = {
    cabinClass: "2",
    adultCount: 1,
    childCount: 0,
    infantCount: 0,
    resultFareType: "2",
    preferredDepartureTime: moment(),
    ...defaultInitState
}

const initMultiState = {
    cabinClass: "2",
    adultCount: 1,
    childCount: 0,
    infantCount: 0,
    resultFareType: "2"
}

const SearchFlightMainComponent = (props) => {
    let {flightObj = {}} = props;
    let [state, setState] = useState(initState)
    let [multiState, setMultiState] = useState(initMultiState)
    let [journeyType, setJourneyType] = useState("0");
    useEffect(() => {
        if (flightObj.journeyType) {
            setJourneyType(flightObj.journeyType)
        } else {
            setJourneyType("1")
        }
    }, [])

    useEffect(() => {
        setState({
            ...state,
            adultCount: 1,
            childCount: 0,
            infantCount: 0,
        })
    }, [state.resultFareType == 3 || state.resultFareType == 5])
    return (
        <div>
            <div className="radio-box mb-3">
                <Radio
                    name={'flightType'}
                    options={flightTypeOption}
                    value={journeyType}
                    onChange={(value) => {
                        setJourneyType(value)
                    }}/>
            </div>

            {["1", "2"].includes(journeyType) ?
                <RoundTripSearchComponent
                    journeyType={journeyType}
                    {...props}
                    state={state}
                    setState={setState}
                    changeJourneyType={(value) => {
                        setJourneyType(value)
                    }}
                /> :
                null
            }
            {/*   {["3"].includes(journeyType) ?
                <MultiCitySearchComponent
                    journeyType={journeyType}
                    {...props}
                    state={multiState}
                    setState={setMultiState}
                    changeJourneyType={(value) => {
                        setJourneyType(value)
                    }}
                /> :
                null
            }*/}
        </div>
    )
}

export default SearchFlightMainComponent
