import React, {useEffect, useState} from "react"
import FlightInfoComponent from "./components/FlightInfoComponent";
import {FlightSummary} from "./components/summary";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import _ from "lodash";
import {holdFlightBookFxn, lccFlightTicketFxn,} from "../flightSearch/actions";
import ReviewBookingComponent from "./components/ReviewBookingComponent";
import Passengers from "./components/passengers";
import PaymentComponent from "./components/paymentComponent";
import {ArrowIcon, ItineraryIcon, PassengerIcon, ReviewIcon, PaymentIcon} from "../../components/imgComp"
import PromotionalModal from "../ModalComponent/promotionalModal";
import {Modal, notification, Skeleton} from "../../components/Elements";
import {reissueTicketFxn} from "../ticketList/action";
import ConfirmationModal from "../ModalComponent/confirmationModal";

let {notifyModal} = Modal;
const contactInitState = {
    email: "",
    contactNo: ""
}
const OneWayBooking = (props) => {
    const {flightObj, traceId, selectedFlight} = useSelector((state) => ({
        flightObj: state.flightsReducer.flightObj,
        selectedFlight: state.flightsReducer.selectedFlight,
        traceId: state.flightsReducer.traceId,
        fareQuoteObj: state.flightsReducer.fareQuoteObj,
    }))
    let {fareObject} = props;
    const navigate = useNavigate();
    const [state, setState] = useState(contactInitState);
    const [totalPaidAmount, setTotalPaidAmount] = useState(0)
    const [convinceFees, setConvinceFees] = useState(0)
    const [xtravelMarginData, setXtravelMarginData] = useState({})
    let dispatch = useDispatch()
    const [activeTab, setActiveTab] = useState(1)
    let [passengerState, setPassengerState] = useState({})
    const [ticketId, setTicketId] = useState(null);
    const [showPromoModal, setShowPromoModal] = useState(false);
    const [promotionData, setPromotionData] = useState({})
    console.log(selectedFlight, "seleced")
    const events = {
        _updateState: (data) => {
            setState((prevState) => {
                return {
                    ...prevState,
                    ...data
                }
            })
        },

        addPassenger: () => {
            setActiveTab(2)
            window.scrollTo(0, 0)
        },
        handleBookTicket: () => {

            setShowPromoModal(true);

        },

        bookTicket: async () => {
            setShowPromoModal(false)
            if (flightObj?.PNR) {
                events.reissueTicket();
                return;
            }
            let {
                adultPassengers = [],
                childPassengers = [],
                infantPassengers = [],
                contactState = {},
                gstState = {}
            } = passengerState;
            let allPassenger = [];
            if (adultPassengers && adultPassengers.length) {
                _.each(adultPassengers, (item) => {
                    allPassenger.push({...item, passengerType: 1})
                })
            }
            if (childPassengers && childPassengers.length) {
                _.each(childPassengers, (item) => {
                    allPassenger.push({...item, passengerType: 2})
                })
            }
            if (infantPassengers && infantPassengers.length) {
                _.each(infantPassengers, (item) => {
                    allPassenger.push({...item, passengerType: 3})
                })
            }

            let obj = {
                traceId: traceId,
                resultIndex: selectedFlight['ResultIndex'],
                allPassenger,
                fareQuote: fareObject,
                isLcc: selectedFlight['IsLCC'],
                flightObj: flightObj,
                ...passengerState.state,
                ...contactState,
                ...gstState,
                ...promotionData,
                totalPaidAmount,
                xtravelMarginData,
                convinceFees
            }

            // if (flightObj?.PNR) {
            //     let reissueData = {
            //         BookingId: flightObj.BookingId,
            //         PNR: flightObj.PNR,
            //         SearchType: flightObj.SearchType,
            //         traceId: flightObj.traceId
            //     };
            //
            //     try {
            //         let resp = await dispatch(reissueTicketFxn(reissueData));
            //          console.log(resp, "resppppp")
            //     } catch (error) {
            //         // console.error("Error in reissuing ticket:", error);
            //     }
            //     return;
            // }

            let {ticketId, sessionId, success, message} = await dispatch(lccFlightTicketFxn(obj));
            if (message?.toLowerCase().includes("timeout")) {
                notification.error({message: "Request Timed Out", description: "Please try again."});
                navigate("/flight-search");
                return;
            }
            if (success) {
                setTicketId(ticketId);
                dispatch({type: "UPDATE_SESSION_ID", sessionId: sessionId})
                // navigate(`/response/${ticketId}`);
                navigate(`/response/${ticketId}`, {state: {params: {sessionId}, passengerState}});
            } else {
                // notifyModal({message: message})
                navigate(`/response/${ticketId}`, {state: {params: {sessionId}}});
            }
        },

        holdBookingFxn: async () => {

            let {
                adultPassengers = [],
                childPassengers = [],
                infantPassengers = [],
                contactState = {},
                gstState = {}
            } = passengerState;
            let allPassenger = [];
            if (adultPassengers && adultPassengers.length) {
                _.each(adultPassengers, (item) => {
                    allPassenger.push({...item, passengerType: 1})
                })
            }
            if (childPassengers && childPassengers.length) {
                _.each(childPassengers, (item) => {
                    allPassenger.push({...item, passengerType: 2})
                })
            }
            if (infantPassengers && infantPassengers.length) {
                _.each(infantPassengers, (item) => {
                    allPassenger.push({...item, passengerType: 3})
                })
            }
            // let result = await events.fareQuote()
            //  console.log(fareObject, "fare obj")
            let obj = {
                traceId: traceId,
                resultIndex: selectedFlight['ResultIndex'],
                allPassenger,
                fareQuote: fareObject,
                isLcc: selectedFlight['IsLCC'],
                flightObj: flightObj,
                ...passengerState.state,
                ...contactState,
                ...gstState,
                ...promotionData,
                totalPaidAmount,
                xtravelMarginData,
                convinceFees
            }

            let {data, ticketId, sessionId, success} = await dispatch(holdFlightBookFxn(obj));

            if (success) {
                setTicketId(ticketId);
                dispatch({type: "UPDATE_SESSION_ID", sessionId: sessionId})
                // navigate(`/response/${ticketId}`);
                navigate(`/response/${ticketId}`, {state: {params: {sessionId}, data, passengerState}});
            } else {
                // navigate(`/response/${ticketId}`);
            }
        },

        goBackToPreviousTab: () => {
            if (activeTab > 0) {
                let currentTab = activeTab - 1;
                window.scrollTo(0, 0)
                if (currentTab == 0) {
                    navigate('/flight-search')
                } else {
                    setActiveTab(currentTab);
                }
            }
        },
        reissueTicket: async () => {
            if (flightObj?.PNR) {
                let reissueData = {
                    resultIndex: selectedFlight['ResultIndex'],
                    traceId: traceId,
                    tokenId: flightObj.tokenId,
                    fareObject: fareObject,
                    totalPaidAmount
                };

                try {
                    let resp = await dispatch(reissueTicketFxn(reissueData));
                    if (resp) {
                        notification.success({message: resp.message || "Ticket reissued successfully"})
                        navigate('/ticket-list')
                    } else {
                        notification.warning({message: resp.message || "Error occurred"})
                    }
                } catch (error) {
                    notification.error({message: "Error Reissuing Ticket"});
                }

            }
        }

    }


    return (
        <>
            <div className="checkout-box bg-gray">
                <div className="flight-booking bg-white spacing-40">
                    <div className="container position-relative">
                        <div className="col-lg-12">
                            <ul className="nav nav-tabs border-0 gap-3" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button
                                        className={`nav-link  rounded-pill border-0 ${activeTab == 1 ? "active" : ""}`}
                                        id="passenger-details-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#passenger-details"
                                        type="button"
                                        role="tab"
                                        disabled={activeTab !== 1}
                                        aria-controls="passenger-details"
                                        aria-selected="false">
                                        <img src={PassengerIcon} alt={'arrow'}/>
                                        Passenger Details

                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button
                                        className={`nav-link  rounded-pill border-0 ${activeTab == 2 ? "active" : ""}`}
                                        id="review-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#review"
                                        type="button"
                                        role="tab"
                                        disabled={activeTab !== 2}
                                        aria-controls="review"
                                        aria-selected="false"
                                    >
                                        <img src={ReviewIcon} alt={'arrow'}/>
                                        Review
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button
                                        className={`nav-link  rounded-pill border-0 ${activeTab == 3 ? "active" : ""}`}
                                        id="payment-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#payment"
                                        type="button"
                                        role="tab"
                                        disabled={activeTab !== 3}
                                        aria-controls="payment"
                                        aria-selected="false">
                                        <img src={PaymentIcon} alt={'arrow'}/>
                                        Payment
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>


                <div className="spacing-40 container">

                    <div className="row row-gap-3">
                        <div className="col-lg-9">

                            <div className="tab-content" id="myTabContent">

                                <div className={`tab-pane fade ${activeTab == 1 ? "show active" : ""}`}
                                     id="passenger-details" role="tabpanel"
                                     aria-labelledby="passenger-details-tab">
                                    <div className="head d-flex gap-3 align-items-center mb-3">
                                        {/*<span>Select from History</span>*/}
                                        <button
                                            className="btn p-0 bg-transparent ms-auto d-flex align-items-center gap-2"
                                            onClick={events.goBackToPreviousTab}>
                                            Back to Search <img src={ArrowIcon} alt={'arrow'}/>
                                        </button>
                                    </div>

                                    <FlightInfoComponent selectedFlight={selectedFlight} flightObj={flightObj}/>

                                    <Passengers {...props} flightObj={flightObj}
                                                selectedFlight={selectedFlight}
                                                _updateSummary={(resp) => {
                                                    setPassengerState(resp)
                                                }}
                                                callbackState={(resp) => {
                                                    setPassengerState(resp)
                                                    setActiveTab(2)
                                                    window.scrollTo(0, 0)
                                                }}/>

                                </div>


                                <div className={`tab-pane fade ${activeTab === 2 ? "show active" : ""}`}
                                     id="review"
                                     role="tabpanel"
                                     aria-labelledby="review-tab">
                                    <>
                                        <div className="head d-flex gap-3 align-items-center mb-3">
                                            <span>Select from History</span>
                                            <button
                                                className="btn p-0 bg-transparent ms-auto d-flex align-items-center gap-2"
                                                onClick={events.goBackToPreviousTab}>

                                                Back to Search <img src={ArrowIcon} alt={'arrow'}/>
                                            </button>
                                        </div>
                                        <FlightInfoComponent selectedFlight={selectedFlight} flightObj={flightObj}/>
                                        <ReviewBookingComponent
                                            passengerState={passengerState}
                                            selectedFlight={selectedFlight}
                                            traceId={traceId}
                                            flightObj={flightObj}
                                            callbackState={() => {
                                                setActiveTab(3)
                                                window.scrollTo(0, 0)
                                            }}
                                            bookState={events.holdBookingFxn}
                                            backtosearch={() => {
                                                events.goBackToPreviousTab()
                                            }}
                                        />
                                    </>
                                </div>


                                <div className={`tab-pane fade ${activeTab == 3 ? "show active" : ""}`} id="payment"
                                     role="tabpanel"
                                     aria-labelledby="payment-tab">
                                    <div className="head d-flex gap-3 align-items-center mb-3">
                                        <button
                                            className="btn p-0 bg-transparent ms-auto d-flex align-items-center gap-2"
                                            onClick={events.goBackToPreviousTab}>

                                            Back to Search <img src={ArrowIcon} alt={'arrow'}/>
                                        </button>
                                    </div>

                                    {ticketId === null && (
                                        <>
                                            <FlightInfoComponent selectedFlight={selectedFlight} flightObj={flightObj}/>

                                            <PaymentComponent passengerState={passengerState}
                                                              fareObject={fareObject}
                                                              traceId={traceId}
                                                              state={state}
                                                              selectedFlight={selectedFlight}
                                                              callbackState={events.handleBookTicket}
                                                              totalPaidAmount={totalPaidAmount}
                                                              xtravelMarginData={xtravelMarginData}
                                                              convinceFees={convinceFees}
                                                // setTicketId={setTicketId}

                                            />
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            {
                                fareObject && fareObject.ResultIndex ? (
                                    <FlightSummary flightData={selectedFlight}
                                                   callBack={(totalPaidAmount) => {
                                                       setTotalPaidAmount(totalPaidAmount)
                                                   }}
                                                   passengerState={passengerState}
                                                   convinceAmount={convinceFees}
                                                   convienceFeesCallback={(convinceAmount) => {
                                                       setConvinceFees(convinceAmount)
                                                   }}
                                                   xtravelMarginDataCallback={(marginData) => {
                                                       setXtravelMarginData(marginData)
                                                   }}
                                    />
                                ) : <>
                                    <Skeleton rows={5}/>
                                </>
                            }
                        </div>
                    </div>
                </div>
                {showPromoModal && (
                    <ConfirmationModal
                        visible={showPromoModal}
                        onClose={() => setShowPromoModal(false)}
                        onYesClick={events.bookTicket}
                        onNoClick={() => events.bookTicket}
                        passengerState={passengerState}
                        fareObject={fareObject}
                        totalPaidAmount={totalPaidAmount}
                        // callbackState={(resp) => setPromotionData(resp)}
                    />
                )}

            </div>
        </>
    )
}
export default OneWayBooking
