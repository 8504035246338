import React, {useRef, useState} from "react";
import {useDispatch} from "react-redux";
import moment from "moment";
import {DatePicker, Table, Tooltip, Popconfirm, notification} from "../../components/Elements";
import {
    displayDate,
    getAirlineLogo,
    getTotalDurationSegments,
    TicketStatusObject,
    ReleaseStatusObject,
    BookingStatusObject,
    FilterStatusObject
} from "../../components/utils/appUtils";
import {
    getAndUpdateTicketDetailsFxn,
    releasePnrFxn,
    sendEmailFxn, sendHoldingEmailFxn,
    ticketListBackofficeFxn
} from "./action";
import AllStopsDetailsPopover from "../flightSearchResult/components/AllStopsDetailsPopover";
import FlightImg from "../../components/flightImg";
import ViewTicketDrawer from "./components/viewTicketDrawer";

const StatusComponent = (props) => {
    let {bookingStatusCode, releaseStatus, responseStatus, response, bookingResponse, fareQuote, TicketStatus} = props;

    let pnrValue = (data) => {
        if (data && data['PNR']) {
            return (
                <div className={'mt-1 btn label label-light label-md'}>
                    PNR - {data['PNR']}
                </div>
            )
        }
    }
    return (
        <div>
            {responseStatus && !releaseStatus && !TicketStatus ?
                <div>
                    <label className={`label label-${responseStatus == 1 ? "success" : "danger"}`}>
                        {TicketStatusObject[responseStatus]}
                    </label>
                    <br/>
                    {pnrValue(response)}
                </div>
                : releaseStatus ?
                    <div>
                        <label className={`label label-${releaseStatus == 1 ? "warning" : "danger"}`}>
                            {ReleaseStatusObject[releaseStatus]}
                        </label>
                    </div>
                    : <div>
                        {BookingStatusObject[bookingStatusCode] ?
                            <label className={`label label-${bookingStatusCode == 1 ? "warning" : "danger"}`}>
                                {BookingStatusObject[bookingStatusCode]}
                            </label> : null}
                        <br/>
                        {pnrValue(bookingResponse)}
                        <br/>
                        <small className="ms-2 f-10">
                            PNR Limit Time: <br/>
                            <span style={{color: 'red'}}
                                  className={'none-span'}>{displayDate(fareQuote?.LastTicketDate)}</span>
                        </small>
                    </div>
            }
        </div>
    )
}

const FlightNonStopComponent = (props) => {
    let {data} = props;
    let {Segments, FareRules: fareRules} = data;
    let allSegmentData = Segments[0];
    let segmentStarting = allSegmentData[0];
    let lastSegment = allSegmentData[allSegmentData.length - 1];

    return (
        <div className={'track-flights newClass'} style={{width: '100%'}}>
            <div className="flight-summary">
                <div className="flight-summary-row">
                    <ul>
                        <li className="p-0">
                            <p style={{fontSize: '10px'}}>
                                <div>
                                    <img
                                        className="airlineLogo-small"
                                        src={getAirlineLogo(segmentStarting?.Airline?.AirlineCode ?? '')}
                                        alt=""
                                    />
                                </div>
                                <strong>{segmentStarting?.Airline?.AirlineName ?? ''}</strong>
                            </p>
                            <h6>
                                <strong style={{fontSize: '10px'}}>
                                    {segmentStarting?.Origin?.Airport?.AirportCode ?? ''}&nbsp;
                                    {displayDate(segmentStarting?.Origin?.DepTime) ?? ''}

                                </strong> <small style={{fontSize: '10px'}} className="d-block">
                                <p>
                                    {segmentStarting?.Origin?.Airport?.CityName ?? ''} -{' '}<br/>
                                    <div className={'airport-name'}>
                                        {segmentStarting?.Origin?.Airport?.AirportName ?? ''}
                                    </div>
                                </p>
                            </small>
                            </h6>

                            <div>
                                <span>
                                    <AllStopsDetailsPopover allSegmentData={allSegmentData}>
                                        <p style={{fontSize: '10px'}} className={'non-stop-p'}>Non-Stop</p>
                                    </AllStopsDetailsPopover>
                                    <FlightImg/>
                                </span>
                            </div>


                            <h6>
                                <strong style={{fontSize: '10px'}}>
                                    {lastSegment?.Destination?.Airport?.AirportCode ?? ''} &nbsp;
                                    {displayDate(lastSegment?.Destination?.ArrTime) ?? ''}
                                </strong>
                                <small style={{fontSize: '10px'}} className="d-block">
                                    <p>
                                        {lastSegment?.Destination?.Airport?.CityName ?? ''} -{' '}<br/>
                                        <div className={'airport-name'}>
                                            {lastSegment?.Destination?.Airport?.AirportName ?? ''}
                                        </div>
                                    </p>
                                </small>
                            </h6>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
const FlightStopsComponent = (props) => {
    let {data, item} = props;
    let {Segments, FareRules: fareRules} = data;
    let allSegmentData = Segments[0];
    let segmentStarting = allSegmentData[0];
    let lastSegment = allSegmentData[allSegmentData.length - 1];
    let originObj = fareRules[0];
    let destinationObj = fareRules[fareRules.length - 1];
    let stopsComponent = (
        <>
            <span><FlightImg/></span>
            <h6 style={{fontSize: '10px'}}>
                <AllStopsDetailsPopover allSegmentData={allSegmentData}>
                    {allSegmentData.length - 1} Stop(s)
                </AllStopsDetailsPopover>
                <small style={{fontSize: '10px'}} className="d-block">
                    {originObj && originObj.Origin ? originObj.Origin : ""}
                    &nbsp;-&nbsp;
                    {destinationObj && destinationObj.Destination ? destinationObj.Destination : ""}
                </small>
                <small style={{fontSize: '10px'}} className="d-block">
                    {getTotalDurationSegments(allSegmentData)}
                </small>
            </h6>
            <span><FlightImg/></span>
        </>
    )

    return (
        <div className={'track-flights newClass'}>
            <div className="flight-summary">
                <div className="flight-summary-row">
                    <ul>
                        <li className="p-0">
                            <p style={{fontSize: '12px'}}>
                                <div>
                                    <img
                                        className="airlineLogo-small"
                                        src={getAirlineLogo(segmentStarting?.Airline?.AirlineCode ?? '')}
                                        alt=""
                                    />
                                </div>
                                <strong
                                    style={{fontSize: '10px'}}>{segmentStarting?.Airline?.AirlineName ?? ''}</strong>
                            </p>
                            <h6>
                                <strong style={{fontSize: '10px'}}>
                                    {segmentStarting?.Origin?.Airport?.AirportCode ?? ''}&nbsp;
                                    {displayDate(segmentStarting?.Origin?.DepTime) ?? ''}
                                </strong> <small className="d-block" style={{fontSize: '10px'}}>
                                <p>
                                    {segmentStarting?.Origin?.Airport?.CityName ?? ''} -{' '}<br/>
                                    <div className={'airport-name'}>
                                        {segmentStarting?.Origin?.Airport?.AirportName ?? ''}
                                    </div>
                                </p>
                            </small>
                            </h6>
                            {stopsComponent}

                            <h6>
                                <strong style={{fontSize: '10px'}}>
                                    {lastSegment?.Destination?.Airport?.AirportCode ?? ''} &nbsp;
                                    {displayDate(lastSegment?.Destination?.ArrTime)}
                                </strong>
                                <small style={{fontSize: '10px'}} className="d-block">
                                    <p>
                                        {lastSegment?.Destination?.Airport?.CityName ?? ''} -{' '}<br/>
                                        <div className={'airport-name'}>
                                            {lastSegment?.Destination?.Airport?.AirportName ?? ''}
                                        </div>
                                    </p>
                                </small>
                            </h6>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

const DepositList = (props) => {
    let {user} = props
    const dispatch = useDispatch();
    let tableRef = useRef()
    let initialFilter = {
        pnr: "",
        bookingDate: "",
        name: '',
        agentName: "",
        ticketId: '',
        flightDate: '',
        status: '',
        fromAirport: '',
        toAirport: '',
        requestId: ''
    }
    const [filters, setFilters] = useState(initialFilter);
    const [totalItems, setTotalItems] = useState(0);
    let initialState = {
        visible: false,
        ticketId: '',
        bookingId: '',
        pnr: '',
    }
    const [requestDrawer, setRequestDrawer] = useState(initialState);

    const apiRequest = async (params) => {
        // console.log(params, "params-----")
        return new Promise(async (resolve) => {
            // Apply the date filter if available
            if (filters.date) {
                params.createdAt = {
                    $gte: moment(filters.date).startOf('day').toDate(),
                    $lte: moment(filters.date).endOf('day').toDate()
                };
            }

            // Apply agent name filter
            if (filters.agentName) {
                params.name = filters.agentName;
            }


            if (filters.name) {
                params['response.FlightItinerary.Passenger'] = {
                    $elemMatch: {FirstName: {$regex: filters.name, $options: 'i'}}
                };
            }
            if (filters.status) {
                const statusValue = filters.status;
                let statusField = '';
                let statusCode = null;

                switch (statusValue) {
                    case '0':
                        params['responseStatus'] = {$in: [2, 3]};
                        break;
                    case '1':
                        statusField = 'response.TicketStatus';
                        statusCode = 1;
                        break;
                    case '2':
                        params['responseStatus'] = {$nin: [1, 2]};
                        params['releaseStatus'] = {$ne: 1};
                        params['bookingResponse.Status'] = 1;
                        break;
                    case '3':
                        params['response.TicketStatus'] = {$ne: 1};
                        params['releaseStatus'] = 1;
                        break;
                    default:
                        break;
                }

                if (statusField !== '' && statusCode !== null) {
                    params[statusField] = statusCode;
                }
            }
            // Apply from and to airport filters
            if (filters.fromAirport) {
                params['response.FlightItinerary.Segments'] = {
                    $elemMatch: {
                        $or: [
                            {'Airline.AirlineCode': {$regex: filters.fromAirport, $options: 'i'}},
                            {'Airline.AirlineName': {$regex: filters.fromAirport, $options: 'i'}}
                        ]
                    }
                };
            }

            if (filters.toAirport) {
                params['response.FlightItinerary.Segments'] = {
                    $elemMatch: {
                        $or: [
                            {'Airline.AirlineCode': {$regex: filters.toAirport, $options: 'i'}},
                            {'Airline.AirlineName': {$regex: filters.toAirport, $options: 'i'}}
                        ]
                    }
                };
            }

            // Apply PNR filter if available
            if (filters.pnr) {
                params['response.PNR'] = filters.pnr;
            }

            // Apply request ID filter if available
            if (filters.requestId) {
                params.requestId = filters.requestId;
            }

            // Dispatch API request
            let resp = await dispatch(ticketListBackofficeFxn({
                ...params,
                regExFilters: ['response.PNR', 'name']
            }));

            setTotalItems(resp.total);
            resolve(resp);
        });
    };


    const handleFilterChange = (e) => {
        let {name, value} = e.target;
        if (name == 'pnr') {
            value = value.toUpperCase()
        }
        setFilters((prev) => ({...prev, [name]: value}));
    };

    const applyFilters = () => {
        tableRef.current.reload()
    };

    const clearFilters = () => {
        setFilters(initialFilter)
        setTimeout(() => {
            tableRef.current.reload()
        }, 200)
    };

    const showRequestDrawer = (ticketId, bookingId, pnr) => {
        setRequestDrawer({
            visible: true,
            ticketId: ticketId,
            bookingId: bookingId,
            pnr: pnr
        })
    };

    const hideRequestDrawer = () => {
        setRequestDrawer(initialState)
    };
    const events = {
        updateDetailEvent: async (ticketId) => {
            let resp = await dispatch(getAndUpdateTicketDetailsFxn({ticketId}));
            if (resp && resp.success) {
                setTimeout(() => {
                    tableRef.current.reload()
                    window.location.reload()
                }, 1000)
            }
        },
        releasePnr: async (record) => {
            // console.log(record, "record----")
            let valData = {
                bookingId: record?.bookingResponse?.BookingId,
                traceId: record?.TraceId,
                source: record?.bookingResponse?.FlightItinerary?.Source,
                tokenId: record?.TokenId,
                ticketId: record?._id
            }
            let resp = await dispatch(releasePnrFxn(valData))
            if (resp.success) {
                tableRef.current.reload()
                // setTicketList(prevList =>
                //     prevList.map(ticket =>
                //         ticket._id === ticketId ? {...ticket, releaseStatus: 1} : ticket
                //     )
                // );

            }
        },
        sendMailFxn: async (record, ticketId) => {
            if (ticketId && record.response) {
                await dispatch(sendEmailFxn({ticketId}));
            } else if (ticketId && record.bookingResponse)
                await dispatch(sendHoldingEmailFxn({ticketId}));
            else {
                notification.error({message: 'Ticket not found!'})
            }

        }
    }

    const columns = [
        {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            width: 50,
            render: (val, record, index) => {
                return index + 1
            }
        },
        {
            title: 'PNR / Status',
            dataIndex: 'response',
            key: 'response',
            width: 150,
            render: (item, record) => {
                // console.log(record, "record-----")
                return (

                    <>
                        <StatusComponent {...record}/>
                        {/*{
                            item &&
                            <>
                            <span
                                style={{
                                    display: 'inline-block',
                                    backgroundColor: '#f0f0f0',
                                    padding: '2px 6px',
                                    borderRadius: '4px',
                                    fontSize: '12px',
                                    fontWeight: 'bold',
                                    border: '1px solid #d9d9d9'
                                }}
                            >
                        {item?.PNR ?? '--'}
                    </span><br/>
                                <span
                                    style={{
                                        display: 'inline-block',
                                        backgroundColor: 'rgb(13,181,97)',
                                        padding: '2px 6px',
                                        borderRadius: '4px',
                                        fontSize: '9px',
                                        fontWeight: 'bold',
                                        border: '1px solid #d9d9d9',
                                        width: 'fit-content',
                                        color: 'white',
                                        marginTop: '5px'
                                    }}
                                >
                                {(TicketStatusObject[item?.TicketStatus]?.toUpperCase() ?? '--')}
                            </span>
                            </>
                        }*/}
                        {record.error && record.error['ErrorCode'] &&
                        <>
                            <label className={'label label-error mt-1'}>
                                Error
                            </label>
                        </>
                        }
                        {/* {
                            (record.bookingResponse && !record.response) &&
                            <>
                                <div style={{
                                    display: 'inline-block',
                                    backgroundColor: '#ada008',
                                    padding: '2px 6px',
                                    borderRadius: '4px',
                                    fontSize: '12px',
                                    fontWeight: 'bold',
                                    border: '1px solid #d9d9d9',
                                    color: 'white'
                                }}>
                                    Hold
                                </div>
                            </>
                        }*/}
                    </>
                )
            },
        },
        {
            title: 'Passengers',
            dataIndex: 'Passengers',
            key: 'Passengers',
            width: 150,
            render: (item) => {
                return item?.map(passenger => `${passenger.FirstName} ${passenger.LastName}`).join(', ') || '';
            }
        },
        {
            title: 'Booking ID /Req./ Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: 150,
            render: (item, record) => {
                return (
                    <>
                        {record.response && (
                            <>
                                <strong>{record?.response?.BookingId ?? '--'}</strong>
                                <br/>
                            </>
                        )}
                        <button className={'btn btn-link sm'}
                                onClick={() => {
                                    showRequestDrawer(record._id, record?.response?.BookingId, record?.response?.PNR)
                                }}
                        >{record.reqCount && `${record.reqCount} Request`}
                        </button>
                        <br/>

                        {item ? `${displayDate(item)}` : '--'}
                    </>
                );
            }
        },
        /*{
            title: 'Flight Details',
            dataIndex: 'response',
            key: 'response',
            width: 200, // Adjust the width as needed
            render: (item, record) => {
                let segments = [];
                let quoteFare = [];
                let {fareQuote} = record;
                if (fareQuote && fareQuote.Segments && fareQuote.Segments.length) {
                    /!*  if (record.bookingType == "Inbound") {
                          segments = fareQuote.Segments[0] || [];
                          quoteFare = fareQuote;
                      } else {
                          segments = fareQuote.Segments[0];
                          quoteFare = fareQuote;
                      }*!/
                    segments = fareQuote.Segments[0] || [];
                    quoteFare = fareQuote;
                }
                return item ? (
                    <>
                        {segments && segments.length ?
                            <div className={'flight-detail'}>
                                {segments.length > 1 ?
                                    <WithStopComponent data={quoteFare} item={item} record={record}/> :
                                    <NonStopComponent data={quoteFare} item={item} record={record}/>}
                            </div> : null
                        }
                    </>
                ) : null;
            }
        },*/
        {
            title: 'Flight Details',
            dataIndex: 'response',
            key: 'response',
            width: 200, // Adjust the width as needed
            render: (item, record) => {
                let segments = [];
                let quoteFare = [];
                let {fareQuote} = record;
                if (fareQuote && fareQuote.Segments && fareQuote.Segments.length) {
                    segments = fareQuote.Segments[0] || [];
                    quoteFare = fareQuote;
                }
                return (
                    <>
                        {segments && segments.length ?
                            <div className={'flight-detail'}>
                                {segments.length > 1 ?
                                    <FlightStopsComponent data={quoteFare} item={item} record={record}/> :
                                    <FlightNonStopComponent data={quoteFare} item={item} record={record}/>}
                            </div> : null
                        }
                    </>
                );
            }
        },
        {
            title: 'Agent Name',
            dataIndex: 'name',
            key: 'name',
            width: 150,
            render: (item, record) => (
                item && <span>{item}<br/>({record.companyName && record.companyName})</span>
            )
        },
        {
            title: 'Actions',
            dataIndex: 'agentName',
            key: 'agentName',
            width: 150,
            render: (item, record) => {
                let {bookingStatusCode, releaseStatus, responseStatus, response, bookingResponse} = record;
                let isHoldStatus = false;

                if (responseStatus) {
                    isHoldStatus = false; // If there's a response status, it's not a hold
                } else if (releaseStatus) {
                    isHoldStatus = false; // If there's a release status, it's not a hold
                } else if (BookingStatusObject[bookingStatusCode] === "Hold") {
                    isHoldStatus = true; // If bookingStatusCode is 1 (Hold)
                }

                return (
                    <div className="d-flex flex-wrap gap-2">
                        {
                            record?.response ?
                                <Tooltip title={'View response'}>
                                    <a
                                        href={`/response/${record?._id}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <img
                                            className={'ticketBackofficeIcons'}
                                            src="../icons-img/view.png"
                                            width="10"
                                            alt="eye-icon"
                                        />
                                    </a>
                                </Tooltip> : ''
                        }

                        {
                            (record?.bookingResponse && !record.response) ?
                                <>
                                    <Tooltip title={'View hold response'}>
                                        <a
                                            href={`/response/${record?._id}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <img
                                                className={'ticketBackofficeIcons'}
                                                src="../icons-img/view.png"
                                                width="10"
                                                alt="eye-icon"
                                            />
                                        </a>
                                    </Tooltip>
                                    {/*<Tooltip title={'Book Ticket'}>
                                        <img
                                            className={'iconheight'}
                                            src="../icons-img/booking.png"
                                            width="20"
                                            alt="eye-icon"
                                        />
                                    </Tooltip>*/}
                                    {/*<Tooltip title={'Release'}>
                                        <img
                                            className={'iconheight'}
                                            src="../icons-img/release.png"
                                            width="20"
                                            alt="eye-icon"
                                        />
                                    </Tooltip>*/}
                                </> : ''
                        }

                        {
                            record?.error?.ErrorCode ?
                                <Tooltip title={'View error'}>
                                    <a
                                        href={`/error-page/${record?._id}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <img
                                            className={'ticketBackofficeIcons'}
                                            src="../icons-img/view.png"
                                            width="10"
                                            alt="eye-icon"
                                        />
                                    </a>
                                </Tooltip> : ''
                        }
                        {
                            (record?.error || record?.bookingResponse) ? '' :
                                <>
                                    {/*<Tooltip title={'Cancel'}>
                                        <img
                                            className={'ticketBackofficeIcons'}
                                            src="../icons-img/cancel.png"
                                            width="20"
                                            alt="eye-icon"
                                        />
                                    </Tooltip>*/}
                                    {/*<Tooltip title={'Re-Issue'}>
                                        <img
                                            className={'ticketBackofficeIcons'}
                                            src="../icons-img/reissue.png"
                                            width="20"
                                            alt="eye-icon"
                                        />
                                    </Tooltip>*/}
                                    {/*<Tooltip title={'Amendment'}>
                                        <img
                                            className={'ticketBackofficeIcons'}
                                            src="../icons-img/amendement.png"
                                            width="20"
                                            alt="eye-icon"
                                        />
                                    </Tooltip>*/}
                                    {/*<Tooltip title={'All Tickets'}>
                                        <img
                                            className={'ticketBackofficeIcons'}
                                            src="../icons-img/ticekts.png"
                                            width="20"
                                            alt="eye-icon"
                                        />
                                    </Tooltip>*/}
                                    {/*<Tooltip title={'Invoice'}>
                                        <img
                                            className={'ticketBackofficeIcons'}
                                            src="../icons-img/invoice.png"
                                            width="20"
                                            alt="eye-icon"
                                        />
                                    </Tooltip>
                                    <Tooltip title={'Booking Details'}>
                                        <img
                                            className={'ticketBackofficeIcons'}
                                            src="../icons-img/contact-card.png"
                                            width="30"
                                            alt="eye-icon"
                                        />
                                    </Tooltip>*/}
                                </>
                        }


                        {user.userType == "itTeam" && (record.bookingStatusCode == 1 || record.responseStatus == 1) ?
                            <>
                                <Tooltip title={'Get & Update'}>
                                    <Popconfirm
                                        title={'Are you sure, you want to update Details'}
                                        onConfirm={() => {
                                            events.updateDetailEvent(record._id)
                                        }}>
                                        <a>
                                            <i className={'bx bx-refresh bx-sm'}></i>
                                        </a>
                                    </Popconfirm>
                                </Tooltip>
                            </> : null
                        }
                        {isHoldStatus ? (
                            <>
                                <Tooltip title={'Release Pnr'}>
                                    <Popconfirm
                                        title="Are you sure you want to release this PNR?"
                                        onConfirm={() => events.releasePnr(record)}
                                        okText="Yes"
                                        cancelText="No">
                                    <span>
                                        <img
                                            className={'ticketBackofficeIcons'}
                                            src="../icons-img/release.png"
                                            width="30"
                                            alt="eye-icon"
                                        />
                                    </span>
                                    </Popconfirm>
                                </Tooltip>
                            </>
                        ) : null}


                        {((record?.response && !record?.response?.error) || isHoldStatus) && (
                            <Tooltip title="Email E-Ticket">
                                <Popconfirm
                                    title="Are you sure you want to Email E-Ticket?"
                                    onConfirm={() => events.sendMailFxn(record, record._id)}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <span>
                                        <img
                                            className="ticketBackofficeIcons"
                                            src="/icons-img/mail.png"
                                            width="30"
                                            alt="mail-icon"
                                        />
                                    </span>
                                </Popconfirm>
                            </Tooltip>
                        )}

                    </div>
                )
            }
        },

    ]

    const NonStopComponent = (props) => {
        let {data, item} = props;
        let {Segments, FareRules: fareRules} = data;
        let allSegmentData = Segments[0];
        return (
            <div className={'track-flights newClass'} style={{width: '100%'}}>
                <div className="flight-summary">
                    <div className="flight-summary-row">
                        <ul>
                            <li className="p-0">
                                <p style={{fontSize: '10px'}}>
                                    <div>
                                        <img
                                            className="airlineLogo-small"
                                            src={getAirlineLogo(item?.FlightItinerary?.Segments[0]?.Airline?.AirlineCode ?? '')}
                                            alt=""
                                        />
                                    </div>
                                    <strong>{item?.FlightItinerary?.Segments[0]?.Airline?.AirlineName ?? ''}</strong>
                                </p>
                                <h6>
                                    <strong style={{fontSize: '10px'}}>
                                        {item?.FlightItinerary?.Origin ?? ''}
                                        {moment(item?.FlightItinerary?.Segments[0]?.Origin?.DepTime).format('hh:mm a') ?? ''}

                                    </strong> <small style={{fontSize: '10px'}} className="d-block">
                                    <p>
                                        {item?.FlightItinerary?.Segments[0]?.Origin?.Airport?.CityName ?? ''} -{' '}<br/>
                                        <div className={'airport-name'}>
                                            {item?.FlightItinerary?.Segments[0]?.Origin?.Airport?.AirportName ?? ''}
                                        </div>
                                    </p>
                                </small>
                                </h6>

                                <span>
                                    <AllStopsDetailsPopover allSegmentData={allSegmentData}>
                                        <p style={{fontSize: '10px'}} className={'non-stop-p'}>Non-Stop</p>
                                    </AllStopsDetailsPopover>
                                    <FlightImg/>
                                </span>


                                <h6>
                                    <strong style={{fontSize: '10px'}}>
                                        {item?.FlightItinerary?.Destination ?? ''}
                                        {moment(item?.FlightItinerary?.Segments[0]?.Destination?.ArrTime).format('hh:mm a') ?? ''}
                                    </strong>
                                    <small style={{fontSize: '10px'}} className="d-block">
                                        <p>
                                            {item?.FlightItinerary?.Segments?.[item?.FlightItinerary?.Segments?.length - 1]?.Destination?.Airport?.CityName ?? ''}{' '}
                                            -{' '} <br/>
                                            <div className={'airport-name'}>
                                                {item?.FlightItinerary?.Segments?.[item?.FlightItinerary?.Segments?.length - 1]?.Destination?.Airport?.AirportName ?? ''}
                                            </div>
                                        </p>
                                    </small>
                                </h6>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }


    const WithStopComponent = (props) => {
        let {data, item} = props;
        let {Segments, FareRules: fareRules} = data;
        let allSegmentData = Segments[0];
        let segmentStarting = allSegmentData[0];
        let originObj = fareRules[0];
        let destinationObj = fareRules[fareRules.length - 1];
        let stopsComponent = (
            <>
                <span><FlightImg/></span>
                <h6 style={{fontSize: '10px'}}>
                    <AllStopsDetailsPopover allSegmentData={allSegmentData}>
                        {allSegmentData.length - 1} Stop(s)
                    </AllStopsDetailsPopover>
                    <small style={{fontSize: '10px'}} className="d-block">
                        {originObj && originObj.Origin ? originObj.Origin : ""}
                        &nbsp;-&nbsp;
                        {destinationObj && destinationObj.Destination ? destinationObj.Destination : ""}
                    </small>
                    <small style={{fontSize: '10px'}} className="d-block">
                        {getTotalDurationSegments(allSegmentData)}
                    </small>
                </h6>
                <span><FlightImg/></span>
            </>
        )

        return (
            <div className={'track-flights newClass'}>
                <div className="flight-summary">
                    <div className="flight-summary-row">
                        <ul>
                            <li className="p-0">
                                <p style={{fontSize: '12px'}}>
                                    <div>
                                        <img
                                            className="airlineLogo-small"
                                            src={getAirlineLogo(item?.FlightItinerary?.Segments[0]?.Airline?.AirlineCode ?? '')}
                                            alt=""
                                        />
                                    </div>
                                    <strong>{item?.FlightItinerary?.Segments[0]?.Airline?.AirlineName ?? ''}</strong>
                                </p>
                                <h6>
                                    <strong style={{fontSize: '10px'}}>
                                        {item?.FlightItinerary?.Origin ?? ''}
                                        {moment(item?.FlightItinerary?.Segments[0]?.Origin?.DepTime).format('hh:mm a') ?? ''}
                                    </strong> <small className="d-block" style={{fontSize: '10px'}}>
                                    <p>
                                        {item?.FlightItinerary?.Segments[0]?.Origin?.Airport?.CityName ?? ''} -{' '}<br/>
                                        <div className={'airport-name'}>
                                            {item?.FlightItinerary?.Segments[0]?.Origin?.Airport?.AirportName ?? ''}
                                        </div>
                                    </p>
                                </small>
                                </h6>
                                {stopsComponent}

                                <h6>
                                    <strong style={{fontSize: '10px'}}>
                                        {item?.FlightItinerary?.Destination ?? ''}
                                        {moment(item?.FlightItinerary?.Segments[0]?.Destination?.ArrTime).format('hh:mm a') ?? ''}
                                    </strong>
                                    <small style={{fontSize: '10px'}} className="d-block">
                                        <p>
                                            {item?.FlightItinerary?.Segments?.[item?.FlightItinerary?.Segments?.length - 1]?.Destination?.Airport?.CityName ?? ''}{' '}
                                            -{' '}<br/>
                                            <div className={'airport-name'}>
                                                {item?.FlightItinerary?.Segments?.[item?.FlightItinerary?.Segments?.length - 1]?.Destination?.Airport?.AirportName ?? ''}
                                            </div>
                                        </p>
                                    </small>
                                </h6>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        )
    }

    return (
        <div className="row">
            <div className="col-lg-12">
                <h5 className="mb-4">Ticket List Backoffice({totalItems ? totalItems : 0})</h5>
                <div className="mb-4 col-lg-12 d-flex flex-wrap">
                    <div className={"col-2 ms-2 mb-2"}>
                        <input
                            style={{height: '38px'}}
                            type="text"
                            name="pnr"
                            className="form-control font12"
                            value={filters.pnr}
                            onChange={handleFilterChange}
                            placeholder="PNR"
                        />
                    </div>
                    <div className={"col-2 ms-2 mb-2"}>
                        <input
                            style={{height: '38px'}}
                            type="text"
                            name="name"
                            className="form-control font12"
                            value={filters.name}
                            onChange={handleFilterChange}
                            placeholder="Filter by first name of passengers"
                        />
                    </div>
                    <div className={"col-2 ms-2 mb-2"}>
                        <input
                            style={{height: '38px'}}
                            type="text"
                            name="agentName"
                            className="form-control font12"
                            value={filters.agentName}
                            onChange={handleFilterChange}
                            placeholder="Filter by Agent Name"
                        />
                    </div>
                    <div className={"col-2 ms-2 mb-2"}>
                        <input
                            style={{height: '38px'}}
                            type="text"
                            name="fromAirport"
                            className="form-control font12"
                            value={filters.fromAirport}
                            onChange={handleFilterChange}
                            placeholder="Filter by From Airport"
                        />
                    </div>
                    <div className={"col-2 ms-2 mb-2"}>
                        <input
                            style={{height: '38px'}}
                            type="text"
                            name="toAirport"
                            className="form-control font12"
                            value={filters.toAirport}
                            onChange={handleFilterChange}
                            placeholder="Filter by To Airport"
                        />
                    </div>
                    <div className={"col-2 ms-2 mb-2"}>
                        <select
                            name="status"
                            className="form-control font12"
                            value={filters.status}
                            onChange={handleFilterChange}
                            style={{height: '38px'}}
                        >
                            <option value="">Status</option>
                            {Object.keys(FilterStatusObject).map((status) => (
                                <option key={status} value={status}>
                                    {FilterStatusObject[status]}
                                </option>
                            ))}
                        </select>
                    </div>


                    <div className={"col-2 ms-2 mb-2"}>
                        <DatePicker
                            placeholder={'Filter by Date'}
                            value={filters.date}
                            onChange={(value) => {
                                setFilters({...filters, date: value})
                            }}/>
                    </div>
                    <div className={"col-2 ms-2 mb-2"}>
                        <input
                            style={{height: '38px'}}
                            type="text"
                            name="requestId"
                            className="form-control font12"
                            value={filters.requestId}
                            onChange={handleFilterChange}
                            placeholder="Filter by RequestId"
                        />
                    </div>
                    <div className={"col-4 mb-2"}>
                        <button
                            className="btn btn-primary ms-2 font12"
                            onClick={applyFilters}
                            style={{height: '38px'}}
                        >
                            Apply Filters
                        </button>
                        <button
                            className="btn btn-primary ms-2 font12"
                            onClick={clearFilters}
                            style={{height: '38px'}}
                        >
                            Clear Filters
                        </button>
                    </div>
                </div>
                <div>
                    <Table
                        columns={columns}
                        apiRequest={apiRequest}
                        ref={tableRef}
                        defaultSortOrder='descend'
                    />
                </div>
                {
                    requestDrawer.visible &&
                    <ViewTicketDrawer
                        visible={requestDrawer.visible}
                        onclose={hideRequestDrawer}
                        ticketId={requestDrawer.ticketId}
                        bookingId={requestDrawer.bookingId}
                        pnr={requestDrawer.pnr}
                    />
                }
            </div>
        </div>
    );
}

export default DepositList;
